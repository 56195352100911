<template>
    <div>
        <dxDataGrid
                :PageSize = "5"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 5, 10, 15 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'id', colCaption: 'ID карты', sortOrder: undefined, visible: false, datatype: '', format: ''},
                        { colDataField: 'group', colCaption: 'ID группы карт', sortOrder: undefined, visible: false, datatype: '', format: '' },
                        { colDataField: 'contract_id', colCaption: 'ID договора', sortOrder: undefined, visible: false, datatype: '', format: '' },
                        { colDataField: 'number', colCaption: 'Номер карты', sortOrder: 'asc', datatype: '', format: '' },
                        { colDataField: 'status', colCaption: 'Статус', sortOrder: undefined, datatype: '', format: '' },
                        { colDataField: 'comment', colCaption: 'Комментарий', sortOrder: undefined, datatype: '', format: '' },
                        { colDataField: 'product', colCaption: 'Тип продукта ', sortOrder: undefined, visible: false, datatype: '', format: '' },
                        { colDataField: 'payment_of_tolls', colCaption: 'Возможность оплаты дорожных сборов', sortOrder: undefined, visible: false, datatype: '', format: '' },
                        { colDataField: 'sync_group_state', colCaption: 'Статус синхронизации', sortOrder: undefined, visible: false, datatype: '', format: '' }
                ]"

                HeaderText = "Топливные карты"
                HeaderIcon = "mdi-credit-card-multiple-outline"
                :EditedItem = "{ }"
                :DefaultItem = "{ }"
                :AddFields = "[]"
                :EditedFields = "[]"
                EndPoint=""
                :ParentParams="{ client: this.clientID }"
                :GroupPanelVisible = "false"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "true"
                :SearchWidth = "235"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="false"
                :BtnEdit="false"
                :BtnDelete="false"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 60%'"
                :DataReturnF="this.$store.getters.cardsList"
                :UsingRules="'card'"
                :ColumnHidingEnabled="false"
                
        ></dxDataGrid>
        <dxDataGrid
                :PageSize = "5"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 5, 10, 15 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'id', colCaption: 'ID операции', sortOrder: undefined, visible: false, datatype: '', format: ''},
                        { colDataField: 'time', colCaption: 'Дата', sortOrder: 'asc', datatype: 'date',format: 'dd.MM.yyyy HH:mm:ss' },
                        { colDataField: 'product', colCaption: 'Продукт', sortOrder: undefined, datatype: '', format: '' },
                        { colDataField: 'service_center', colCaption: 'Точка', sortOrder: undefined, datatype: '', format: ''},
                        { colDataField: 'price', colCaption: 'Цена на АЗС', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00' },
                        { colDataField: 'amount', colCaption: 'Кол-во', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00' },
                        { colDataField: 'cost', colCaption: 'Стоимость на АЗС', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00' },
                        { colDataField: 'discount_price', colCaption: 'Цена для Клиента', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00' },
                        { colDataField: 'discount_cost', colCaption: 'Стоимость для Клиента', sortOrder: undefined, datatype: 'number', format: ',###,###,###,##0.00' }
                ]"

                HeaderText = "Последние транзакции"
                HeaderIcon = "mdi-transfer"
                :EditedItem = "{ }"
                :DefaultItem = "{ }"
                :AddFields = "[]"
                :EditedFields = "[]"
                EndPoint=""
                :ParentParams="{ client: this.clientID }"
                :GroupPanelVisible = "false"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "true"
                :SearchWidth = "300"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="false"
                :BtnEdit="false"
                :BtnDelete="false"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 100%'"
                :DataReturnF="this.$store.getters.TransactionsList"
                :WordWrapEnabled="true"
                :UsingRules="'trans'"
                :ColumnHidingEnabled="false"
        ></dxDataGrid>
    </div>
</template>

<script>
import dxDataGrid from '@/components/dxDataGridO.vue'

    export default {
        name: "cardListForm",
        data() {
            return {
                clientID: this.$store.getters.UserId
            }
        },
        async beforeMount() {
            //console.log(this.$store.getters.idSettings)
            await this.$store.dispatch('loadUserProcSettings',this.$store.getters.idSettings)
            
            //console.log(this.$store.getters.userProcSettings)
        },
        mounted() {
            this.$store.commit('SetCardID', undefined)
            const setting2 = {
                gpnapi: this.$store.getters.gpn24,
                sessionid: this.$store.getters.SessionId,
                contractid: this.$store.getters.LastContract,
                cardid: this.$store.getters.CardID,
                gpnurl: this.$store.getters.url24,
                
                
            }
            //console.log(setting2)
            this.$store.dispatch('loadTransactionList', setting2)
        },
        components:{
            dxDataGrid
        }
    }
</script>


