import * as sha512 from 'js-sha512'
import { Buffer } from 'buffer'; 

import { getOrCreateSessionKey } from './unitCode'

const KEY = () => getOrCreateSessionKey();

const encryptSTRJ = (message) => {
    if (!message) return ''
    
    const messageStr = typeof message === 'string' 
      ? message 
      : String(message)
      
    if (messageStr.trim() === '') return ''
  
    try {
      let encryptedMessage = ''
      let keyHash = sha512(KEY())
      
      for (let i = 0; i < messageStr.length; i++) {
        let charCode = messageStr.charCodeAt(i) ^ keyHash.charCodeAt(i % keyHash.length)
        encryptedMessage += String.fromCharCode(charCode)
      }
  
      return Buffer.from(encryptedMessage).toString('base64')
    } catch (e) {
      //console.error('Encryption error:', e)
      return ''
    }
  }

const decryptSTRJ = (encryptedMessage) => {
    // Сначала проверяем существование
    if (!encryptedMessage) return ''
    
    // Преобразуем в строку если это не строка
    const messageStr = typeof encryptedMessage === 'string' 
      ? encryptedMessage 
      : String(encryptedMessage)
      
    // Теперь проверяем пустоту
    if (messageStr.trim() === '') return ''
  
    try {
      let message = Buffer.from(messageStr, 'base64').toString()
      let decryptedMessage = ''
      let keyHash = sha512(KEY())
      
      for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i) ^ keyHash.charCodeAt(i % keyHash.length)
        decryptedMessage += String.fromCharCode(charCode)
      }
  
      return decryptedMessage
    } catch (e) {
      //console.error('Decryption error:', e)
      return ''
    }
  }

  const decryptArrayJ = (encryptedArray) => {
    // Проверка на пустые значения
    if (!encryptedArray) return []
    
    try {
        const jsonStr = decryptSTRJ(encryptedArray)
        // Проверка на пустую строку после расшифровки
        if (!jsonStr || jsonStr.trim() === '') return []
        
        // Проверка что строка является валидным JSON
        try {
            const parsed = JSON.parse(jsonStr)
            // Проверка что результат это массив
            return Array.isArray(parsed) ? parsed : []
        } catch (parseError) {
            //console.error('JSON Parse error:', parseError)
            return []
        }
    } catch (e) {
        //console.error('Decryption error:', e)
        return []
    }
}

const encryptArrayJ = (array) => {
    // Проверка входных данных
    if (!array || !Array.isArray(array)) return []
    
    try {
        // Проверка что массив можно сериализовать
        const jsonStr = JSON.stringify(array)
        if (!jsonStr || jsonStr === '[]') return []
        
        const encrypted = encryptSTRJ(jsonStr)
        return encrypted || []
    } catch (e) {
        //console.error('Encryption error:', e)
        return []
    }
}

export {
    encryptSTRJ,
    decryptSTRJ,
    encryptArrayJ,
    decryptArrayJ
}