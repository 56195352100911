<template>
    <div>
    <alertMessage :DialogMsg="alertMessage.alertMessageShow" :DialogImgColor="alertMessage.alertImgColor" :DialogImg="alertMessage.alertImg" :DialogHeaderText="alertMessage.alertHeaderText" :DialogText="alertMessage.alertText"></alertMessage>
    <v-dialog v-model="dialog" persistent  :max-width="maxWidth">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">{{ formTitle }}</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout row wrap >
                        <v-flex :class="field.sizeField" sm v-for="(field, index) in dialogFields" :key="index" >
                            <v-text-field :ref="editedItem[field.model]" :rules="field.rulesField" :counter="field.counterField" :mask="field.maskField"
                                          :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" v-if="drawComponent && field.typeField=='text' &&field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" type="password" v-if="field.typeField=='pass' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" :rules="field.rulesField"  :counter="field.counterField" :mask="field.maskField" type="number" v-if="field.typeField=='number' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" :rules="emailRules"  :counter="field.counterField" :mask="field.maskField" type="text" v-if="field.typeField=='email' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-checkbox color="info" :label="field.label" v-model="editedItem[field.model]" v-if="field.typeField=='check' && field.dialog.indexOf(formTitle)!=-1"></v-checkbox>
                            <dadata :FullAddressIN = "addressSelected" v-if="drawComponent && field.typeField=='dadata' && field.dialog.indexOf(formTitle)!=-1"></dadata>
                            <bnkseek :BnkId="bnkID" v-if="drawComponent && field.typeField=='bnkseek' && field.dialog.indexOf(formTitle)!=-1"></bnkseek>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small dark color="primary" @click.native="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLimit" persistent  max-width="700" >
        <v-card>
            
            <v-card-title>
                <span class="headline" style="color:#1976d2">{{ formTitle }}</span>
            </v-card-title>
            
            <hr />
                <v-container >
                    <v-layout justify-center >
                        
                            
                            <v-row  class="pa-2 ma-0">
                                
                                <v-col cols="12" md="6" class="pa-1 ma-0">
                                    <v-combobox class="pa-0 pr-2 ma-0"
                                    v-model="selectedItemTypeLimit"
                                    :items="TypeLimitReturn"
                                    item-text="name"
                                    item-value="id"
                                    label="Выберите категорию"
                                    :loading = "loadingF"
                                    :value = "selectedItemTypeLimit"
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="12" md="6" class="pa-1 ma-0">
                                    <v-combobox class="pa-0 pr-0 ma-0"
                                    v-model="selectedItemGroupLimit"
                                    :items="GroupLimitReturn"
                                    item-text="name"
                                    item-value="id"
                                    label="Выберите группу"
                                    :loading = "loadingF"
                                    :value = "selectedItemGroupLimit"
                                    :clearable="true"
                                    ></v-combobox>
                                </v-col>

                                 <v-col cols="12" md="6" class="pa-1 ma-0">
                                    <v-radio-group v-model="rowKolvoSumma" row class="pa-0 ma-0">
                                        <v-radio label="Количество" value="radio-1"></v-radio>
                                        <v-radio label="Сумма" value="radio-2"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" md="6" class="pa-1 ma-0">
                                </v-col>
                                <v-container class="pa-0 ma-0">
                                    <v-row  class="pa-0 ma-0">
                                        <v-col cols="12" md="6" class="pa-1 ma-0" v-if="rowKolvoSumma=='radio-1'">
                                            <v-text-field class="pa-0 pr-2 ma-0"
                                                v-model="KolvoLimits"
                                                label="Количество"
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pa-1 ma-0" v-if="rowKolvoSumma=='radio-1'">
                                            <v-combobox class="pa-0 pr-0 ma-0"
                                            v-model="selectedItemUnitLimit"
                                            :items="UnitLimitReturn"
                                            item-text="value"
                                            item-value="id"
                                            label="Единицы измерения"
                                            :loading = "loadingF"
                                            :value = "selectedItemUnitLimit"
                                            :clearable="true"
                                            ></v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-container class="pa-0 ma-0">
                                    <v-row  class="pa-0 ma-0">
                                        <v-col cols="12" md="6" class="pa-1 ma-0" v-if="rowKolvoSumma=='radio-2'">
                                            <v-text-field class="pa-0 pr-2 ma-0"
                                                v-model="SumLimits"
                                                label="Сумма"
                                                type="number"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pa-1 ma-0" v-if="rowKolvoSumma=='radio-2'">
                                            <v-combobox class="pa-0 pr-0 ma-0"
                                            v-model="selectedItemCurrencyLimit"
                                            :items="CurrencyLimitReturn"
                                            item-text="code"
                                            item-value="id"
                                            label="Валюта"
                                            :loading = "loadingF"
                                            :value = "selectedItemCurrencyLimit"
                                            ></v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-col cols="12" md="6" class="pa-1 ma-0">
                                    <v-text-field class="pa-0 pr-2 ma-0"
                                        v-model="PeriodKolvoLimits"
                                        label="Период кол-во"
                                        type="number"
                                        :disabled="selectedItemPeriodLimit.id == 2 || selectedItemPeriodLimit.id == 1 ? true : false"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="pa-1 ma-0">
                                    <v-combobox class="pa-0 pr-0 ma-0"
                                    v-model="selectedItemPeriodLimit"
                                    :items="PeriodLimitReturn"
                                    item-text="period"
                                    item-value="id"
                                    label="Период"
                                    :loading = "loadingF"
                                    :value = "selectedItemPeriodLimit"
                                    ></v-combobox>
                                </v-col>

                                <v-col cols="12" md="6" class="pa-1 ma-0">
                                    <v-text-field class="pa-0 pr-2 ma-0"
                                        v-model="KolvoTransactionsLimits"
                                        label="Количество транзакций"
                                        type="number"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="pa-1 ma-0">
                                </v-col>

                                <v-col cols="12" md="6" class="pa-1 ma-0">
                                    <v-checkbox class="pa-0 pr-2 ma-0"
                                        v-model="checkBoxDopSettings"
                                        label="Дополнительные параметры"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="6" class="pa-0 ma-0">
                                </v-col>

                                <v-col cols="12" md="6" class="pa-0 ma-0" v-if="checkBoxDopSettings">
                                    <v-subheader class="pa-0 pr-0 ma-0">Дни недели</v-subheader>
                                </v-col>
                                <v-col cols="12" md="6" class="pa-0 ma-0" v-if="checkBoxDopSettings">
                                </v-col>

                                <v-col cols="12" md="1" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-checkbox class="pa-0 pr-2 ma-0"
                                        v-model="checkPn"
                                        label="ПН"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="1" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-checkbox class="pa-0 pl-2 ma-0"
                                        v-model="checkVt"
                                        label="ВТ"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="1" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-checkbox class="pa-0 pl-2 ma-0"
                                        v-model="checkSr"
                                        label="СР"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="1" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-checkbox class="pa-0 pl-2 ma-0"
                                        v-model="checkCht"
                                        label="ЧТ"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="1" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-checkbox class="pa-0 pl-2 ma-0"
                                        v-model="checkPt"
                                        label="ПТ"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="1" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-checkbox class="pa-0 pl-2 ma-0"
                                        v-model="checkSu"
                                        label="СБ"
                                    ></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="1" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-checkbox class="pa-0 pl-2 ma-0"
                                        v-model="checkVs"
                                        label="ВС"
                                    ></v-checkbox>
                                </v-col>
                                
                                <v-layout   v-if="checkBoxDopSettings">
                                    <v-radio-group v-model="checkWorkDay" row class="pa-0 ma-0">
                                        <v-radio class="pa-0 pl-1 ma-0" label="Применяется всегда" value="radio-1"></v-radio>
                                        <v-radio class="pa-0 pl-2 ma-0" label="Только в рабочие дни" value="radio-2"></v-radio>
                                        <v-radio class="pa-0 pl-2 ma-0" label="Только в выходные дни" value="radio-3"></v-radio>
                                    </v-radio-group>
                                </v-layout>

                                <v-col cols="12" md="6" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-text-field class="pa-0 pr-2 ma-0"
                                        v-model="TimeStartLimits"
                                        label="Время с"
                                        type="time"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="pa-1 ma-0" v-if="checkBoxDopSettings">
                                    <v-text-field class="pa-0 pr-2 ma-0"
                                        v-model="TimeEndLimits"
                                        label="Время по"
                                        type="time"
                                    ></v-text-field>
                                </v-col>

                            </v-row>
                            
                           
                        
                    </v-layout>
                </v-container>
                <v-alert
                class="ml-2 mr-2"
                v-model="alertLimit"
                type="error"
                dense
                outlined
                dismissible
                >
                    {{ alertText }}
                </v-alert>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small dark color="primary" @click.native="save">Сохранить</v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRestrictions" persistent  max-width="700" >
        <v-card>
            
            <v-card-title>
                <span class="headline" style="color:#1976d2">{{ formTitle }}</span>
            </v-card-title>
            
            <hr />
                <v-container >
                    <v-layout justify-center >
                        
                            
                            <v-row  class="pa-2 ma-0">
                                <v-col cols="12" md="12" class="pa-1 ma-0">
                                    <v-radio-group v-model="rowRestrictionOn" row class="pa-0 ma-0">
                                        <v-radio label="Разрешающее ограничение" value="radio-1"></v-radio>
                                        <v-radio label="Запрещающее ограничение" value="radio-2"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                

                                <v-col cols="12" md="12" class="pa-1 ma-0">
                                    <v-combobox class="pa-0 pr-0 ma-0"
                                    v-model="selectedItemTypeLimit"
                                    :items="TypeLimitReturn"
                                    item-text="name"
                                    item-value="id"
                                    label="Выберите категорию"
                                    :loading = "loadingF"
                                    :value = "selectedItemTypeLimit"
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="12" md="12" class="pa-1 ma-0">
                                    <v-combobox class="pa-0 pr-0 ma-0"
                                    v-model="selectedItemGroupLimit"
                                    :items="GroupLimitReturn"
                                    item-text="name"
                                    item-value="id"
                                    label="Выберите группу"
                                    :loading = "loadingF"
                                    :value = "selectedItemGroupLimit"
                                    ></v-combobox>
                                </v-col>

                                 
                                

                            </v-row>
                            
                           
                        
                    </v-layout>
                </v-container>
                <v-alert
                class="ml-2 mr-2"
                v-model="alertLimit"
                type="error"
                dense
                outlined
                dismissible
                >
                    {{ alertText }}
                </v-alert>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small dark color="primary" @click.native="save">Сохранить</v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRegionLimit" persistent  max-width="700" >
        <v-card>
            
            <v-card-title>
                <span class="headline" style="color:#1976d2">{{ formTitle }}</span>
            </v-card-title>
            
            <hr />
                <v-container >
                    <v-layout justify-center >
                        
                            
                            <v-row  class="pa-2 ma-0">
                                <v-col cols="12" md="12" class="pa-1 ma-0">
                                    <v-radio-group v-model="rowRegionLimitOn" row class="pa-0 ma-0">
                                        <v-radio label="Разрешающее ограничение" value="radio-1"></v-radio>
                                        <v-radio label="Запрещающее ограничение" value="radio-2"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                

                                <v-col cols="12" md="12" class="pa-1 ma-0">
                                    <v-combobox class="pa-0 pr-0 ma-0"
                                    v-model="selectedItemCountry"
                                    :items="CountryReturn"
                                    item-text="value"
                                    item-value="id"
                                    label="Выберите страну"
                                    :loading = "loadingF"
                                    :value = "selectedItemCountry"
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="12" md="12" class="pa-1 ma-0">
                                    <v-autocomplete class="pa-0 pr-0 ma-0"
                                    v-model="selectedItemRegion"
                                    :items="RegionReturn"
                                    item-text="name"
                                    item-value="id"
                                    label="Выберите регион"
                                    :loading = "loadingF"
                                    :value = "selectedItemRegion"
                                    no-data-text="Не найдено"
                                    :clearable="true"
                                    placeholder="Все регионы"
                                    return-object
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="12" class="pa-1 ma-0">
                                    <v-autocomplete class="pa-0 pr-0 ma-0"
                                    v-model="selectedItemAzs"
                                    :items="AzsReturn"
                                    item-text="searchTxt"
                                    item-value="id"
                                    label="Выберите АЗС"
                                    :loading = "loadingAzs"
                                    :value = "selectedItemAzs"
                                    no-data-text="Не найдено"
                                    :clearable="true"
                                    placeholder="Все АЗС"
                                    return-object
                                    ></v-autocomplete>
                                </v-col>
                                 
                                

                            </v-row>
                            
                           
                        
                    </v-layout>
                </v-container>
                <v-alert
                class="ml-2 mr-2"
                v-model="alertLimit"
                type="error"
                dense
                outlined
                dismissible
                >
                    {{ alertText }}
                </v-alert>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small dark color="primary" @click.native="save">Сохранить</v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
    <v-alert
            v-model="alert"
            color="error"
            icon="priority_high"
            outlined
            dismissible
    >
        {{ alertText }}
    </v-alert>

    <v-dialog v-model="dialogDel" persistent  max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">Удаление</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <span >Удалить выбранную запись?</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small color="primary" @click.native="doDelete">Удалить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
              
    <v-container class="mainGridConteiner" :style="maxGridWidth">
        <v-layout justify-center >
            <v-card class="pa-3">
            <dx-data-grid :ref="gridRefName"
                            id="gridContainer"
                            :data-source="DataReturn"
                            :show-borders="true"
                            :hover-state-enabled="true"
                            :allow-column-reordering="allowColumnReordering"
                            :allow-column-resizing="allowColumnResizing"
                            :column-auto-width="columnAutoWidth"
                            :column-hiding-enabled="columnHidingEnabled"
                            :word-wrap-enabled="wordWrapEnabled"
                            @toolbar-preparing="onToolbarPreparing($event)"
                            key="id"
                            @selection-changed="onSelectionChanged"
                >

                <dx-paging :page-size="pageSize"/>
                <dx-pager
                    :show-page-size-selector="showPageSizeSelector"
                    :allowed-page-sizes="allowedPageSizes"
                    :show-info="true"
                />

                <dx-selection mode="single" />
                <dx-group-panel :visible="groupPanelVisible" />
                <dx-load-panel :enabled="false" :visible="false" />
                <dx-filter-row :visible="filterRowVisible" />
                <dx-header-filter :visible="headerFilterVisible" />
                <dx-search-panel :visible="searchVisible" :width="searchWidth" placeholder="Поиск..." />

                <dx-column v-for="(col, index) in columnSettingsIN" :key="index+'-column'" :width="col.colWidth" :data-field="col.colDataField" :caption="col.colCaption" :sort-order="col.sortOrder" :visible.sync="col.visible" />
                

                <dx-summary v-if="summaryVisible" >
                    <dx-group-item v-for="(group, indx) in groupSummarySettingsIN" :key="indx + '-group'"
                                    :show-in-group-footer="group.showInGroupFooter"
                                    :align-by-column="group.alignByColumn"
                                    :column="group.columnGroup"
                                    :summary-type="group.summaryType"
                                    :display-format="group.displayFormat"
                                    :value-format="group.valueFormat"
                    />
                </dx-summary>
                <dx-sort-by-group-summary-info summary-item="count" />
                <div
                                slot="vProgressVuetify"
                                
                        >
                            <div>
                                <p style="margin: 0 4.5em 0em 0em;"></p>
                                <v-progress-circular
                                  :size="40"
                                  color="indigo"
                                  indeterminate
                                  v-if="loadingF"
                                ></v-progress-circular>
                            </div>
                        </div>
                        <div
                                slot="titleGroupVuetify"
                                
                        >
                            <div>
                                <v-chip outlined color="primary">
                                    <v-icon left>{{headerIcon}}</v-icon>{{headerText}}
                                </v-chip>
                                <div>
                                <p style="padding: 0 15em 0em 0;"></p>
                                </div>
                            </div>
                        </div>
                        <div
                                slot="GroupButtonVuetify"
                                
                        >
                            <div>
                                    <v-tooltip bottom class="pa-4">
                                         <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="refreshData()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="btnRefresh"
                                                    v-on="on"
                                                    
                                            >
                                                <v-icon>refresh</v-icon>
                                            </v-btn>
                                         </template>
                                         <span>Обновить</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="addItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnAdd"
                                                    v-on="on"
                                                    class="mx-2"
                                            >
                                                <v-icon>add</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Добавить</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="editItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnEdit"
                                                    v-on="on"
                                                    
                                            >
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Редактировать</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="deleteItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnDelete"
                                                    v-on="on"
                                                    class="mx-2"
                                            >
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Удалить</span>
                                    </v-tooltip>
                                    
                            </div>
                        </div>
            </dx-data-grid >
             </v-card>
        </v-layout>
    </v-container>
</div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import date from 'date-and-time';
    
    import Vuetify from 'vuetify'
    import 'material-design-icons-iconfont/dist/material-design-icons.css'
    import 'vuetify/dist/vuetify.min.css'
    import ru from 'vuetify/es5/locale/ru'
    import alertMessage from "@/components/alertMessage.vue"

    Vue.use(Vuetify, {
        lang: {
            locales: { 'ru': ru },
            current: 'ru'
        }
    })

   import {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxSummary,
    DxGroupItem,
    DxGroupPanel,
    DxHeaderFilter,
    DxSearchPanel,
    DxSortByGroupSummaryInfo,
    DxFilterRow,
    DxLoadPanel,
    DxPaging,
    DxPager
} from 'devextreme-vue/data-grid';


import 'devextreme/dist/css/dx.common.css';


    export default {
        name: "sample",
        components: {
        DxDataGrid,
        DxColumn,
        DxSelection,
        DxSummary,
        DxGroupItem,
        DxGroupPanel,
        DxHeaderFilter,
        DxSortByGroupSummaryInfo,
        DxSearchPanel,
        DxFilterRow,
        DxLoadPanel,
        DxPaging,
        DxPager,
        alertMessage
    },
    props: {
        GridRefName: {
            default () {
                return 'dataGrid'
            }
        },
        AllowColumnReordering: {
            default () {
                return true
            }
        },
        AllowColumnResizing: {
            default () {
                return true
            }
        },
        ColumnAutoWidth: {
            default () {
                return true
            }
        },
        ColumnHidingEnabled: {
            default () {
                return true
            }
        },
        PageSize: {
            default () {
                return 5
            }
        },
        ShowPageSizeSelector: {
            default () {
                return false
            }
        },
        AllowedPageSizes: {
            default () {
                return [ 5 ]
            }
        },
        HeaderText: {
            default () {
                return 'Вам нужно передать заголовок через Props'
            }
        },
        HeaderIcon: {
            default () {
                return ''
            }
        },
        ColumnSettingsIN: {
            default () {
                return []
            }
        },
        SummaryVisible: {
            default () {
                return false
            }
        },
        GroupSummarySettingsIN: {
            default () {
                return []
            }
        },
        GroupPanelVisible: {
            default () {
                return false
            }
        },
        FilterRowVisible: {
            default () {
                return false
            }
        },
        HeaderFilterVisible: {
            default () {
                return false
            }
        },
        SearchVisible: {
            default () {
                return false
            }
        },
        SearchWidth: {
            default () {
                return 300
            }
        },
        BtnRefresh: {
            default () {
                return false
            }
        },
        BtnAdd: {
            default () {
                return false
            }
        },
        BtnEdit: {
            default () {
                return false
            }
        },
        BtnDelete: {
            default () {
                return false
            }
        },
        BtnShipper: {
            default () {
                return false
            }
        },
        MaxWidth: {
            default () {
                return "600px"
            }
        },
        MaxGridWidth: {
            default () {
                return ""
            }
        },
        EditedItem: {
            default () {
                return [{
                    id: ''
                }]
            }
        },
        DefaultItem: {
            default () {
                return [{
                    id: ''
                }]
            }
        },
        EditedFields: {
            default () {
                return [{
                    model: ''
                }]
            }
        },
        AddFields: {
            default () {
                return [{
                    model: ''
                }]
            }
        },
        EndPoint: {
            default () {
                return ''
            }
        },
        ParentParams: {
            default () {
                return null
            }
        },
        DataReturnF: {
            default () {
                return null
            }
        },
        WordWrapEnabled: {
            default () {
                return false
            }
        },
        UsingRules: {
            default () {
                return ''
            }
        },
        AllCard: {
            default () {
                return false
            }
        }
    },
        data() {
            return {
            maxGridWidth: '',
            allCard: false,
            selectedItemTypeLimit: null,
            selectedItemGroupLimit: null,
            rowKolvoSumma: 'radio-1',
            KolvoLimits: 0,
            SumLimits: 0,
            selectedItemPeriodLimit: null,
            PeriodKolvoLimits: 1,
            PeriodLimitReturn: [
                { id: 1, period: 'На транзакцию'},
                { id: 2, period: 'Разовый'},
                { id: 3, period: 'Сутки'},
                { id: 4, period: 'Неделя'},
                { id: 5, period: 'Месяц'},
                { id: 6, period: 'Квартал'},
                { id: 7, period: 'Год'}
            ],
            selectedItemUnitLimit: null,
            selectedItemCurrencyLimit: null,
            KolvoTransactionsLimits: 0,
            checkBoxDopSettings: false,
            checkPn: false,
            checkVt: false,
            checkSr: false,
            checkCht: false,
            checkPt: false,
            checkSu: false,
            checkVs: false,
            TimeStartLimits: '00:00',
            TimeEndLimits: '00:00',
            checkWorkDay: 'radio-1',
            AddLimitOper: false,
            rowRestrictionOn: 'radio-1',
            rowRegionLimitOn: 'radio-1',
            selectedItemCountry: null,
            selectedItemRegion: null,
            selectedItemAzs: null,
            allowColumnReordering: this.AllowColumnReordering,
            allowColumnResizing: this.AllowColumnResizing,
            columnAutoWidth: this.ColumnAutoWidth,
            columnHidingEnabled: this.ColumnHidingEnabled,
            pageSize: this.PageSize,
            showPageSizeSelector: this.ShowPageSizeSelector,
            allowedPageSizes: this.AllowedPageSizes,
            drawComponent: true,
            gridRefName: this.GridRefName,
            expanded: true,
            alert: false,
            alertLimit: false,
            alertDialog: false,
            alertText: '',
            dialog: false,
            dialogLimit: false,
            dialogRestrictions: false,
            dialogRegionLimit: false,
            dialogDel: false,
            dialogSetShipper: false,
            headerText: this.HeaderText,
            headerIcon: this.HeaderIcon,
            columnSettingsIN: this.ColumnSettingsIN,
            summaryVisible: this.SummaryVisible,
            groupSummarySettingsIN: this.GroupSummarySettingsIN,
            groupPanelVisible: this.GroupPanelVisible,
            filterRowVisible: this.FilterRowVisible,
            headerFilterVisible: this.HeaderFilterVisible,
            searchVisible: this.SearchVisible,
            searchWidth: this.SearchWidth,
            btnRefresh: this.BtnRefresh,
            btnAdd: this.BtnAdd,
            btnEdit: this.BtnEdit,
            btnDelete: this.BtnDelete,
            btnShipper: this.BtnShipper,
            loading: false,
            selected: '',
            dataItems: '',
            dataReturn: '',
            editedIndex: -1,
            editedItem: {},
            defaultItem: {},
            editedFields: [],
            addFields: [],
            dialogFields: [],
            endPoint: '',
            parentParams: this.ParentParams,
            maxWidth: "",
            bnkID: '',
            addressSelected: '',
            alertMessage: {alertMessageShow: false, alertImgColor: "error", alertImg: "error_outline", alertHeaderText: 'Ошибка', alertText: 'Ошибка'},
            shipperMailShow: true,
            wordWrapEnabled: false,
            usingRules: '',
            emailRules: [ 
                            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail введен не верно'
                        ]
        }
    },
    created() {

    },
    beforeMount() {
        this.allCard = this.AllCard
        this.gridRefName = this.GridRefName
        this.allowColumnReordering = this.AllowColumnReordering
        this.allowColumnResizing = this.AllowColumnResizing
        this.columnAutoWidth = this.ColumnAutoWidth
        this.columnHidingEnabled = this.ColumnHidingEnabled
        this.pageSize = this.PageSize
        this.showPageSizeSelector = this.ShowPageSizeSelector
        this.allowedPageSizes = this.AllowedPageSizes
        this.headerText = this.HeaderText
        this.headerIcon = this.HeaderIcon
        this.columnSettingsIN = this.ColumnSettingsIN
        this.groupSummarySettingsIN = this.GroupSummarySettingsIN
        this.groupPanelVisible = this.GroupPanelVisible
        this.filterRowVisible = this.FilterRowVisible
        this.headerFilterVisible = this.HeaderFilterVisible
        this.searchVisible = this.SearchVisible
        this.searchWidth = this.SearchWidth
        this.summaryVisible = this.SummaryVisible
        this.editedItem = this.EditedItem
        this.defaultItem = this.DefaultItem
        this.editedFields = this.EditedFields
        this.addFields = this.AddFields
        this.endPoint = this.EndPoint
        this.parentParams = this.ParentParams
        this.btnRefresh = this.BtnRefresh
        this.btnAdd = this.BtnAdd
        this.btnEdit = this.BtnEdit
        this.btnDelete = this.BtnDelete
        this.btnShipper = this.BtnShipper
        this.maxWidth = this.MaxWidth
        this.maxGridWidth = this.MaxGridWidth
        this.dataReturn = this.DataReturnF
        this.wordWrapEnabled = this.WordWrapEnabled
        this.usingRules = this.UsingRules
        this.selectedItemPeriodLimit = this.PeriodLimitReturn[0]

    },
    methods: {
        
        clearLimitForm() {
            //console.log(this.selectedItemCurrencyLimit)
            this.selectedItemTypeLimit = this.TypeLimitReturn[0]
            this.selectedItemGroupLimit = []
            this.rowKolvoSumma = 'radio-1'
            this.KolvoLimits = 0
            this.SumLimits = 0
            this.PeriodKolvoLimits = 1
            this.selectedItemUnitLimit = this.UnitLimitReturn[0]
            this.selectedItemCurrencyLimit = this.CurrencyLimitReturn[0]
            this.selectedItemPeriodLimit = this.PeriodLimitReturn[0]
            this.KolvoTransactionsLimits = 0
            this.checkBoxDopSettings = false
            this.checkPn = false
            this.checkVt = false
            this.checkSr = false
            this.checkCht = false
            this.checkPt = false
            this.checkSu = false
            this.checkVs = false
            this.TimeStartLimits = '00:00'
            this.TimeEndLimits = '00:00'
            this.checkWorkDay = 'radio-1'  
        },
        clearRestrictionForm() {
            //console.log(this.selectedItemCurrencyLimit)
            this.selectedItemTypeLimit = this.TypeLimitReturn[0]
            this.selectedItemGroupLimit = []
            this.rowRegionLimitOnOn = 'radio-1'
            
        },
        clearRegionLimitForm() {
            //console.log(this.selectedItemCurrencyLimit)
            this.selectedItemCountry = this.CountryReturn[0]
            this.selectedItemRegion = []
            this.selectedItemAzs = []
            this.rowRestrictionOn = 'radio-1',
            this.rowRestrictionOff = ''
        },
        reset: function() {
            var vm = this;
            vm.drawComponent = false;
            Vue.nextTick(function() {
                vm.drawComponent = true;
            });
        },
        doAlertMessage(Show, ImgColor, Img, HeaderText, MsgText) {
            this.alertMessage.alertMessageShow = Show
            if(ImgColor != ''){ this.alertMessage.alertImgColor = ImgColor }
            if(Img != ''){ this.alertMessage.alertImg = Img }
            if(HeaderText != ''){ this.alertMessage.alertHeaderText = HeaderText }
            if(MsgText != ''){ this.alertMessage.alertText = MsgText }
        },
        onToolbarPreparing(e) {
            e.toolbarOptions.items.unshift({
                    location: 'after',
                    template: 'vProgressVuetify'
                }, {
                location: 'after',
                template: 'GroupButtonVuetify'
                }, {
                location: 'before',
                template: 'titleGroupVuetify'
            });
        },
        refreshData(){
            //console.log(this.allCard)
            if(this.$store.getters.GetAllCard == false){
                if(this.usingRules == "limits") {
                    const setting1 = {
                        gpnapi: this.$store.getters.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        contractid: this.$store.getters.LastContract,
                        cardid: this.$store.getters.CardID,
                        gpnurl: this.$store.getters.url24,
                    }
                    //console.log(setting2)
                    this.$store.dispatch('loadCardLimits', setting1)
                    //this.addItem()
                }
                if(this.usingRules == "restrictions") {
                    const setting1 = {
                            gpnapi: this.$store.getters.gpn24,
                            sessionid: this.$store.getters.SessionId,
                            contractid: this.$store.getters.LastContract,
                            cardid: this.$store.getters.CardID,
                            gpnurl: this.$store.getters.url24,
                        }
                    //console.log(setting1)
                    this.$store.dispatch('loadCardRestrictions', setting1)
                }
                if(this.usingRules == "RegionLimits") {
                    const setting1 = {
                            gpnapi: this.$store.getters.gpn24,
                            sessionid: this.$store.getters.SessionId,
                            contractid: this.$store.getters.LastContract,
                            cardid: this.$store.getters.CardID,
                            gpnurl: this.$store.getters.url24,
                        }
                    //console.log(setting1)
                    this.$store.dispatch('loadCardRegionLimitList', setting1)
                }
            }
        },
        addItem(){
            if(this.$store.getters.GetAllCard == false){   
                if(this.usingRules == "limits") {
                    this.alertLimit = false
                    this.selected = ''
                    this.AddLimitOper = true
                    this.clearLimitForm()
                    this.dialogLimit = true
                }
                if(this.usingRules == "restrictions") {
                    this.alertLimit = false
                    this.selected = ''
                    this.AddLimitOper = true
                    this.clearRestrictionForm()
                    this.dialogRestrictions = true
                }
                if(this.usingRules == "RegionLimits") {
                    this.alertLimit = false
                    this.selected = ''
                    this.AddLimitOper = true
                    this.clearRegionLimitForm()
                    this.dialogRegionLimit = true
                }
            }
                   
        },
        onSelectionChanged({ selectedRowsData }) {
            this.selected = selectedRowsData[0]
            //console.log(this.selected)
            
        },
        close () {
            if(this.usingRules == "limits") {
                //console.log(this.selectedItemCurrencyLimit)
                this.alertLimit = false
                this.AddLimitOper = false
                this.dialogLimit = false
                this.dialogDel = false
            }
            if(this.usingRules == "restrictions") {
                //console.log(this.selectedItemCurrencyLimit)
                this.alertLimit = false
                this.AddLimitOper = false
                this.dialogRestrictions = false
                this.dialogDel = false
            }
            if(this.usingRules == "RegionLimits") {
                //console.log(this.selectedItemCurrencyLimit)
                this.alertLimit = false
                this.AddLimitOper = false
                this.dialogRegionLimit = false
                this.dialogDel = false
            }

        },
        deleteItem () {
            if(this.$store.getters.GetAllCard == false){
                if(this.selected!='') {
                    this.editedIndex = this.dataItems.indexOf(this.selected)
                    this.dialogDel = true
                }
                else
                {
                    //console.log(this.alert)
                    this.doAlertMessage(true,'','','Внимание','Для удаления, нужно выбрать запись в таблице.')
                    //this.alertText = 'Для удаления, нужно выбрать запись в таблице.'
                    //this.alert = true
                }
            }
        },
        async doDelete(){
            var data
            if(this.$store.getters.GetAllCard == false){
                if(this.usingRules == "limits") {
                    const now = new Date();
                    this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                    this.editedItem = Object.assign({}, this.selected)
                    data = 'limit_id=' + this.editedItem.id + '&contract_id=' + this.$store.getters.LastContract
                    /* await axios({
                        mode: 'cors',
                        method: 'POST',
                        headers: {
                            'api_key': this.$store.getters.gpn24,
                            'session_id': this.$store.getters.SessionId,
                            'date_time': this.dt,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        url: this.$store.getters.url24 + 'vip/v1/removeLimit',
                        //url: 'https://apidemo.opti-24.com/vip/v1/authUser',
                        data: data

                    }) */
                    let dataTr = [
                        {"proc":'removeLimit', "method":'POST', "apikey":this.$store.getters.gpn24, "session_id":this.$store.getters.SessionId, "date_time":this.dt, "url":this.$store.getters.url24 + 'vip/v1/removeLimit', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                    await axios({
                        method: 'POST',
                        headers: {
                            'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                            'Content-Type': 'application/json'
                        },
                        url: this.$store.getters.url24sr,
                        //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                        data: dataTr

                    })
                    .then(async (response) => {
                        //console.log(response)
                        if(response.data.status.code == 200) {
                            this.close()        
                            this.$store.dispatch('deleteCardLimits', this.selected.id)
                            //this.refreshData()
                        }
                        
                        //this.refreshData()
                    })
                    .catch((response) => {
                        //handle error
                        console.log('error', response);
                        console.log('errorType', typeof response);
                        console.log('error', Object.assign({}, response));
                        console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                        console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                        console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                        //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                        //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                        //console.log(response.response.data.error);
                        this.errorMsg = 'Ошибка подключения к Опти24!'
                        this.alert = true
                        //this.errorMsg = response.response.data.error
                        this.disabled = 0
                    });
                }
                if(this.usingRules == "restrictions") {
                    const now = new Date();
                    this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                    this.editedItem = Object.assign({}, this.selected)
                    data = 'restriction_id=' + this.editedItem.id + '&contract_id=' + this.$store.getters.LastContract
                    /* await axios({
                        mode: 'cors',
                        method: 'POST',
                        headers: {
                            'api_key': this.$store.getters.gpn24,
                            'session_id': this.$store.getters.SessionId,
                            'date_time': this.dt,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        url: this.$store.getters.url24 + 'vip/v1/removeRestriction',
                        //url: 'https://apidemo.opti-24.com/vip/v1/authUser',
                        data: data

                    }) */
                    let dataTr = [
                        {"proc":'removeRestriction', "method":'POST', "apikey":this.$store.getters.gpn24, "session_id":this.$store.getters.SessionId, "date_time":this.dt, "url":this.$store.getters.url24 + 'vip/v1/removeRestriction', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                    await axios({
                        method: 'POST',
                        headers: {
                            'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                            'Content-Type': 'application/json'
                        },
                        url: this.$store.getters.url24sr,
                        //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                        data: dataTr

                    })
                    .then(async (response) => {
                        //console.log(response)
                        if(response.data.status.code == 200) {
                            this.close()        
                            this.$store.dispatch('deleteRestrictions', this.selected.id)
                            //this.refreshData()
                        }
                        
                        //this.refreshData()
                    })
                    .catch((response) => {
                        //handle error
                        console.log('error', response);
                        console.log('errorType', typeof response);
                        console.log('error', Object.assign({}, response));
                        console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                        console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                        console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                        //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                        //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                        //console.log(response.response.data.error);
                        this.errorMsg = 'Ошибка подключения к Опти24!'
                        this.alert = true
                        //this.errorMsg = response.response.data.error
                        this.disabled = 0
                    });
                }
                if(this.usingRules == "RegionLimits") {
                    const now = new Date();
                    this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                    this.editedItem = Object.assign({}, this.selected)
                    data = 'regionlimit_id=' + this.editedItem.id + '&contract_id=' + this.$store.getters.LastContract
                    /* await axios({
                        mode: 'cors',
                        method: 'POST',
                        headers: {
                            'api_key': this.$store.getters.gpn24,
                            'session_id': this.$store.getters.SessionId,
                            'date_time': this.dt,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        url: this.$store.getters.url24 + 'vip/v1/removeRegionLimit',
                        //url: 'https://apidemo.opti-24.com/vip/v1/authUser',
                        data: data

                    }) */
                    let dataTr = [
                        {"proc":'removeRegionLimit', "method":'POST', "apikey":this.$store.getters.gpn24, "session_id":this.$store.getters.SessionId, "date_time":this.dt, "url":this.$store.getters.url24 + 'vip/v1/removeRegionLimit', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                    await axios({
                        method: 'POST',
                        headers: {
                            'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                            'Content-Type': 'application/json'
                        },
                        url: this.$store.getters.url24sr,
                        //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                        data: dataTr

                    })
                    .then(async (response) => {
                        //console.log(response)
                        if(response.data.status.code == 200) {
                            this.close()        
                            this.$store.dispatch('deleteCardRegionLimit', this.selected.id)
                            //this.refreshData()
                        }
                        
                        //this.refreshData()
                    })
                    .catch((response) => {
                        //handle error
                        console.log('error', response);
                        console.log('errorType', typeof response);
                        console.log('error', Object.assign({}, response));
                        console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                        console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                        console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                        //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                        //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                        //console.log(response.response.data.error);
                        this.errorMsg = 'Ошибка подключения к Опти24!'
                        this.alert = true
                        //this.errorMsg = response.response.data.error
                        this.disabled = 0
                    });
                }
            }
        },
        async editItem () {
            var TypeListFilter = []
            var GroupListFilter = []
            if(this.$store.getters.GetAllCard == false){
                if(this.usingRules == "limits") {
                    if(this.selected!='') {
                        this.alertLimit = false
                        this.AddLimitOper = false
                        //console.log(this.selectedItemCurrencyLimit)
                        //console.log(this.addressSelected)
                        //this.$root.$emit('setSelectBnkID', this.selected.bank)
                        this.editedIndex = this.DataReturn.indexOf(this.selected)
                        this.editedItem = Object.assign({}, this.selected)
                        if(this.editedItem.productType !== null){
                            TypeListFilter = this.TypeLimitReturn.find(o => o.id === this.editedItem.productType);
                            this.selectedItemTypeLimit = TypeListFilter
                        }
                        if(this.editedItem.productGroup !== null){
                            GroupListFilter = this.GroupLimitReturn.find(o => o.id === this.editedItem.productGroup);
                            this.selectedItemGroupLimit = GroupListFilter 
                            //console.log(GroupListFilter)
                        }
                        else {
                            this.selectedItemGroupLimit = []
                        }
                        if(this.editedItem.amount == true) {
                            this.rowKolvoSumma = 'radio-1'
                            this.KolvoLimits = this.editedItem.amount_value
                            this.selectedItemUnitLimit = this.UnitLimitReturn.find(o => o.id === this.editedItem.amount_unit);
                            //console.log(this.UnitLimitReturn)
                            //console.log(this.selectedItemUnitLimit)
                        }
                        if(this.editedItem.sum == true) {
                            this.rowKolvoSumma = 'radio-2'
                            this.SumLimits = this.editedItem.sum_value
                            this.selectedItemCurrencyLimit = this.CurrencyLimitReturn[0]
                        }
                        this.PeriodKolvoLimits = this.editedItem.time_number
                        this.selectedItemPeriodLimit = this.PeriodLimitReturn.find(o => o.id === this.editedItem.time_type);
                        this.KolvoTransactionsLimits = this.editedItem.transactions_count
                        if(this.editedItem.term == true) {
                            this.checkBoxDopSettings = true
                            if(this.editedItem.term_days != null)
                            {
                                if(this.editedItem.term_days.substr(0,1) == '1')
                                {
                                    this.checkPn = true
                                }
                                if(this.editedItem.term_days.substr(1,1) == '1')
                                {
                                    this.checkVt = true
                                }
                                if(this.editedItem.term_days.substr(2,1) == '1')
                                {
                                    this.checkSr = true
                                }
                                if(this.editedItem.term_days.substr(3,1) == '1')
                                {
                                    this.checkCht = true
                                }
                                if(this.editedItem.term_days.substr(4,1) == '1')
                                {
                                    this.checkPt = true
                                }
                                if(this.editedItem.term_days.substr(5,1) == '1')
                                {
                                    this.checkSu = true
                                }
                                if(this.editedItem.term_days.substr(6,1) == '1')
                                {
                                    this.checkVs = true
                                }
                            }
                            this.checkWorkDay = "radio-" + this.editedItem.term_type
                            this.TimeStartLimits = this.editedItem.term_time_from
                            this.TimeEndLimits = this.editedItem.term_time_to
                        } else {
                            this.checkBoxDopSettings = false
                            this.checkPn = false
                            this.checkVt = false
                            this.checkSr = false
                            this.checkCht = false
                            this.checkPt = false
                            this.checkSu = false
                            this.checkVs = false

                            this.checkWorkDay = "radio-1"
                            this.TimeStartLimits = '00:00'
                            this.TimeEndLimits = '00:00'
                        }
                        

                        //console.log(this.editedItem)
                        //console.log(this.checkWorkDay)
                        //this.dialogFields = this.editedFields
                        //console.log(this.selectedItemCurrencyLimit)
                        this.dialogLimit = true
                    }
                    else
                    {
                        //console.log(this.alert)
                        this.doAlertMessage(true,'','','Внимание','Для редактирования, нужно выбрать запись в таблице.')
                        //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                        //this.alert = true
                    }
                    
                }
                if(this.usingRules == "restrictions") {
                    if(this.selected!='') {
                        this.alertLimit = false
                        this.AddLimitOper = false
                        //console.log(this.selectedItemCurrencyLimit)
                        //console.log(this.addressSelected)
                        //this.$root.$emit('setSelectBnkID', this.selected.bank)
                        this.editedIndex = this.DataReturn.indexOf(this.selected)
                        this.editedItem = Object.assign({}, this.selected)
                        
                        if(this.editedItem.productType !== null){
                            TypeListFilter = this.TypeLimitReturn.find(o => o.id === this.editedItem.productType);
                            this.selectedItemTypeLimit = TypeListFilter
                        }
                        if(this.editedItem.productGroup !== null){
                            GroupListFilter = this.GroupLimitReturn.find(o => o.id === this.editedItem.productGroup);
                            this.selectedItemGroupLimit = GroupListFilter 
                            //console.log(GroupListFilter)
                        }
                        else {
                            this.selectedItemGroupLimit = []
                        }
                        //console.log(this.editedItem.restriction_type)
                        if(this.editedItem.restriction_type == 'Разрешено') {
                            this.rowRestrictionOn = 'radio-1'
                        } else {
                            this.rowRestrictionOn = 'radio-2'
                        }
                        
                            
                        this.dialogRestrictions = true
                    }
                    else
                    {
                        //console.log(this.alert)
                        this.doAlertMessage(true,'','','Внимание','Для редактирования, нужно выбрать запись в таблице.')
                        //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                        //this.alert = true
                    }
                    
                }
                if(this.usingRules == "RegionLimits") {
                    if(this.selected!='') {
                        this.alertLimit = false
                        this.AddLimitOper = false
                        //console.log(this.selectedItemCurrencyLimit)
                        //console.log(this.addressSelected)
                        //this.$root.$emit('setSelectBnkID', this.selected.bank)
                        this.editedIndex = this.DataReturn.indexOf(this.selected)
                        this.editedItem = Object.assign({}, this.selected)

                        //console.log(this.editedItem)
                        var country = ''
                        var region = ''
                        var azs = ''
                        if(this.editedItem.country !== null){
                            country = this.CountryReturn.find(o => o.id === this.editedItem.country);
                            this.selectedItemCountry = country
                        }
                        if(this.editedItem.region !== null){
                            region = this.RegionReturn.find(o => o.id === this.editedItem.region);
                            this.selectedItemRegion = region 
                            //console.log(GroupListFilter)
                        }
                        else {
                            this.selectedItemRegion = []
                        }
                        //(this.selectedItemRegion)
                        //this.editedItem.service_centerid = '8799644'
                        if(typeof this.editedItem.service_centerid !== "undefined"){
                            const setting = {
                                gpnapi: this.$store.getters.gpn24,
                                sessionid: this.$store.getters.SessionId,
                                gpnurl: this.$store.getters.url24,
                                regionid: this.selectedItemRegion.id
                            }
                            await this.$store.dispatch('loadAzsList', setting)
                            .then(() => {
                                console.log(this.AzsReturn)
                                azs = this.AzsReturn.find(o => o.id === this.editedItem.service_centerid);
                                //console.log(azs)
                                this.selectedItemAzs = azs 
                            })
                            //console.log(GroupListFilter)
                        }
                        else {
                            this.selectedItemAzs = []
                        }

                        //console.log(this.editedItem.restriction_type)
                        if(this.editedItem.restriction_type == 'Разрешено') {
                            this.rowRestrictionOn = 'radio-1'
                        } else {
                            this.rowRestrictionOn = 'radio-2'
                        }
                        
                            
                        this.dialogRegionLimit = true
                    }
                    else
                    {
                        //console.log(this.alert)
                        this.doAlertMessage(true,'','','Внимание','Для редактирования, нужно выбрать запись в таблице.')
                        //this.alertText = 'Для редактирования, нужно выбрать запись в таблице.'
                        //this.alert = true
                    }
                    
                }
            }
        },
        async save () {
            if(this.$store.getters.GetAllCard == false){
                var errors = false
                var mylimit = new Object()
                var ProdGroupGet
                var ProdTypeGet
                var limit
                var limitJSON
                if(this.usingRules == "limits") {
                    if(this.selectedItemTypeLimit.length == 0) {
                            errors = true
                            this.alertLimit = true
                            this.alertText = 'Категория обязательна к заполнению!'
                    } else {
                        if(this.rowKolvoSumma == 'radio-1' && this.KolvoLimits == 0) {
                            if(this.KolvoTransactionsLimits == 0) {
                                errors = true
                                this.alertLimit = true
                                this.alertText = 'Нужно установить хотя бы одно ограничение!'
                            }

                        } else {
                            if(this.rowKolvoSumma == 'radio-2' && this.SumLimits == 0) {
                                if(this.KolvoTransactionsLimits == 0) {
                                    errors = true
                                    this.alertLimit = true
                                    this.alertText = 'Нужно установить хотя бы одно ограничение!'
                                }
                            } else {
                                if(this.rowKolvoSumma == 'radio-1' && this.selectedItemUnitLimit.length == 0) {
                                    errors = true
                                    this.alertLimit = true
                                    this.alertText = 'Единицы измерения обязательны к заполнению!'
                                } else {
                                    if(this.rowKolvoSumma == 'radio-2' && this.selectedItemCurrencyLimit.length == 0) {
                                        errors = true
                                        this.alertLimit = true
                                        this.alertText = 'Валюта обязательна к заполнению!'
                                    } else {
                                        if(this.selectedItemPeriodLimit.length == 0) {
                                            errors = true
                                            this.alertLimit = true
                                            this.alertText = 'Период обязателен к заполнению!'
                                        } else {
                                            if(this.selectedItemPeriodLimit.id > 2 && this.PeriodKolvoLimits < 1) {
                                                errors = true
                                                this.alertLimit = true
                                                this.alertText = 'Период кол-во должен быть больше либо равен 1!'
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    if(errors == false) {
                        this.AddLimitOper = false
                        this.dialogLimit=false
                        
                        
                        if(this.selected!='') {
                            mylimit.id = this.selected.id
                        }
                        mylimit.card_id = this.$store.getters.CardID.toString()
                        mylimit.contract_id = this.$store.getters.LastContract
                        
                        ProdGroupGet = this.selectedItemGroupLimit
                        if(ProdGroupGet.length != 0) {
                            mylimit.productGroup = ProdGroupGet.id
                        }

                        ProdTypeGet = this.selectedItemTypeLimit
                        if(ProdTypeGet.length != 0) {
                            mylimit.productType = ProdTypeGet.id
                        }

                        //amount
                        if(this.rowKolvoSumma == 'radio-1' && this.KolvoLimits !== 0) {
                            var amount = 
                                {
                                    value: parseInt(this.KolvoLimits),
                                    unit: this.selectedItemUnitLimit.id
                                }
                            
                            mylimit.amount = amount
                        }
                        
                        //sum
                        if(this.rowKolvoSumma == 'radio-2' && this.SumLimits !== 0) {
                            var sum = 
                                {
                                    currency: this.selectedItemCurrencyLimit.id,
                                    value: this.SumLimits
                                }
                            
                            mylimit.sum = sum
                        }

                        //term
                        if(this.checkBoxDopSettings == true) {
                            var days1 = this.checkPn == true ? 1 : 0 
                            var days2 = this.checkVt == true ? 1 : 0 
                            var days3 = this.checkSr == true ? 1 : 0
                            var days4 = this.checkCht == true ? 1 : 0
                            var days5 = this.checkPt == true ? 1 : 0
                            var days6 = this.checkSu == true ? 1 : 0
                            var days7 = this.checkVs == true ? 1 : 0

                            var checkday = 1
                            if(this.checkWorkDay == 'radio-1') {
                                checkday = 1
                            }
                            if(this.checkWorkDay == 'radio-2') {
                                checkday = 2
                            }
                            if(this.checkWorkDay == 'radio-2') {
                                checkday = 3
                            }
                            var timeSet = false;
                            var time
                            if(this.TimeStartLimits !== '00:00' || this.TimeEndLimits !== '00:00') {
                                timeSet = true
                                time = {
                                    from: this.TimeStartLimits,
                                    to: this.TimeEndLimits
                                }
                            }
                            var term
                            if(timeSet == true) {
                                term = {
                                    time: time,
                                    days: days1.toString() + days2.toString() + days3.toString() + days4.toString() + days5.toString() + days6.toString() + days7.toString(),
                                    type: checkday
                                }
                            } else {
                                term = {
                                    time: null,
                                    days: days1.toString() + days2.toString() + days3.toString() + days4.toString() + days5.toString() + days6.toString() + days7.toString(),
                                    type: checkday
                                }
                            }
                            
                            mylimit.term = term
                        }
                        else {
                            var term1
                            term1 = {
                                time: null,
                                days: null,
                                type: 1
                            }
                            
                            mylimit.term = term1
                        }
                        
                        //transactions
                        if(this.KolvoTransactionsLimits !== 0) {
                            var transactions = {
                                count: parseInt(this.KolvoTransactionsLimits)
                            }
                            mylimit.transactions = transactions
                        }

                        //time
                        var timeLimit = {
                            number: parseInt(this.PeriodKolvoLimits),
                            type: this.selectedItemPeriodLimit.id
                        }
                        mylimit.time = timeLimit

                        
                        
                        limit = Object.assign({}, mylimit)
                        limitJSON = JSON.stringify(limit)
                        //console.log(limit)
                        //console.log(limitJSON)
                        
                        const data = 'limit=[' + limitJSON + ']'
                        //console.log(data)
                        const now = new Date();
                        this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');

                        /* await axios({
                            mode: 'cors',
                            method: 'POST',
                            headers: {
                                'api_key': this.$store.getters.gpn24,
                                'session_id': this.$store.getters.SessionId,
                                'date_time': this.dt,
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            url: this.$store.getters.url24 + 'vip/v1/setLimit',
                            //url: 'https://apidemo.opti-24.com/vip/v1/authUser',
                            data: data

                        }) */
                        let dataTr = [
                            {"proc":'setLimit', "method":'POST', "apikey":this.$store.getters.gpn24, "session_id":this.$store.getters.SessionId, "date_time":this.dt, "url":this.$store.getters.url24 + 'vip/v1/setLimit', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                        await axios({
                            method: 'POST',
                            headers: {
                                'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                                'Content-Type': 'application/json'
                            },
                            url: this.$store.getters.url24sr,
                            //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                            data: dataTr

                        })
                        .then(async (response) => {
                                //console.log(response)
                                if(response.data.status.code == 200) {
                                this.close()        
                                this.refreshData()
                            }
                        })
                        .catch((response) => {
                            //handle error
                            console.log('error', response);
                            console.log('errorType', typeof response);
                            console.log('error', Object.assign({}, response));
                            console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                            console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                            console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                            //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                            //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                            //console.log(response.response.data.error);
                            this.errorMsg = 'Ошибка подключения к Опти24!'
                            this.alert = true
                            //this.errorMsg = response.response.data.error
                            this.disabled = 0
                        });
                    }
                }
                if(this.usingRules == "restrictions") {
                    if(this.selectedItemTypeLimit.length == 0) {
                            errors = true
                            this.alertLimit = true
                            this.alertText = 'Категория обязательна к заполнению!'
                    } 
                    
                    if(errors == false) {
                        this.AddLimitOper = false
                        this.dialogLimit=false
                        
                        if(this.selected!='') {
                            mylimit.id = this.selected.id
                        }
                        mylimit.card_id = this.$store.getters.CardID
                        mylimit.contract_id = this.$store.getters.LastContract
                        
                        ProdGroupGet = this.selectedItemGroupLimit
                        if(ProdGroupGet.length != 0) {
                            mylimit.productGroup = ProdGroupGet.id
                        }

                        ProdTypeGet = this.selectedItemTypeLimit
                        if(ProdTypeGet.length != 0) {
                            mylimit.productType = ProdTypeGet.id
                        }

                        //restrictions
                        if (this.rowRestrictionOn == 'radio-1') {
                            mylimit.restriction_type = 1
                        } else {
                            mylimit.restriction_type = 2
                        }
                        
                        limit = Object.assign({}, mylimit)
                        limitJSON = JSON.stringify(limit)
                        //console.log(limit)
                        //console.log(limitJSON)
                        
                        const data = 'restriction=[' + limitJSON + ']'
                        //console.log(data)
                        const now = new Date();
                        this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');

                        /* await axios({
                            mode: 'cors',
                            method: 'POST',
                            headers: {
                                'api_key': this.$store.getters.gpn24,
                                'session_id': this.$store.getters.SessionId,
                                'date_time': this.dt,
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            url: this.$store.getters.url24 + 'vip/v1/setRestriction',
                            //url: 'https://apidemo.opti-24.com/vip/v1/authUser',
                            data: data

                        }) */
                        let dataTr = [
                            {"proc":'setRestriction', "method":'POST', "apikey":this.$store.getters.gpn24, "session_id":this.$store.getters.SessionId, "date_time":this.dt, "url":this.$store.getters.url24 + 'vip/v1/setRestriction', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                        await axios({
                            method: 'POST',
                            headers: {
                                'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                                'Content-Type': 'application/json'
                            },
                            url: this.$store.getters.url24sr,
                            //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                            data: dataTr

                        })
                        .then(async (response) => {
                            //console.log(response)
                            if(response.data.status.code == 200) {
                                this.close()        
                                this.refreshData()
                            }
                        })
                        .catch((response) => {
                            //handle error
                            console.log('error', response);
                            console.log('errorType', typeof response);
                            console.log('error', Object.assign({}, response));
                            console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                            console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                            console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                            //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                            //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                            //console.log(response.response.data.error);
                            this.errorMsg = 'Ошибка подключения к Опти24!'
                            this.alert = true
                            //this.errorMsg = response.response.data.error
                            this.disabled = 0
                        });
                    }
                }
                if(this.usingRules == "RegionLimits") {
                    if(this.selectedItemCountry.length == 0) {
                            errors = true
                            this.alertLimit = true
                            this.alertText = 'Страна обязательна к заполнению!'
                    } 
                    
                    if(errors == false) {
                        this.AddLimitOper = false
                        this.dialogRegionLimit=false
                        
                        if(this.selected!='') {
                            mylimit.id = this.selected.id
                        }
                        mylimit.card_id = this.$store.getters.CardID
                        mylimit.contract_id = this.$store.getters.LastContract
                        
                        var country = this.selectedItemCountry
                        if(country.length != 0) {
                            mylimit.country = country.id
                        }

                        var region = this.selectedItemRegion
                        if(region.length != 0) {
                            mylimit.region = region.id
                        }
                        try {
                            var azs = this.selectedItemAzs
                            if(azs.length != 0) {
                                mylimit.service_center = azs.id
                            }
                        } catch { 
                            this.AddLimitOper = false
                        }
                        //restrictions
                        if (this.rowRegionLimitOn == 'radio-1') {
                            mylimit.limit_type = 1
                        } else {
                            mylimit.limit_type = 2
                        }
                        
                        limit = Object.assign({}, mylimit)
                        limitJSON = JSON.stringify(limit)
                        //console.log(limit)
                        //console.log(limitJSON)
                        
                        const data = 'region_limit=[' + limitJSON + ']'
                        //console.log(data)
                        const now = new Date();
                        this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');

                        /* await axios({
                            mode: 'cors',
                            method: 'POST',
                            headers: {
                                'api_key': this.$store.getters.gpn24,
                                'session_id': this.$store.getters.SessionId,
                                'date_time': this.dt,
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            url: this.$store.getters.url24 + 'vip/v1/setRegionLimit',
                            //url: 'https://apidemo.opti-24.com/vip/v1/authUser',
                            data: data

                        }) */
                        let dataTr = [
                            {"proc":'setRegionLimit', "method":'POST', "apikey":this.$store.getters.gpn24, "session_id":this.$store.getters.SessionId, "date_time":this.dt, "url":this.$store.getters.url24 + 'vip/v1/setRegionLimit', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                        await axios({
                            method: 'POST',
                            headers: {
                                'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                                'Content-Type': 'application/json'
                            },
                            url: this.$store.getters.url24sr,
                            //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                            data: dataTr

                        })
                        .then(async (response) => {
                            //console.log(response)
                            if(response.data.status.code == 200) {
                                this.close()        
                                this.refreshData()
                            }
                        })
                        .catch((response) => {
                            //handle error
                            console.log('error', response);
                            console.log('errorType', typeof response);
                            console.log('error', Object.assign({}, response));
                            console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                            console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                            console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                            //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                            //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                            //console.log(response.response.data.error);
                            this.errorMsg = 'Ошибка подключения к Опти24!'
                            this.alert = true
                            //this.errorMsg = response.response.data.error
                            this.disabled = 0
                        });
                    }
                }
            }
        }
    },
    mounted() {
        this.$root.$on('closeAlertMessage', (text) => {
            this.alertMessage.alertMessageShow = text
        })
        this.dialogFields = this.addFields

        
    },
    watch: {
        selectedItemTypeLimit() {
            if(this.AddLimitOper == true) {
                this.selectedItemGroupLimit = []
                this.selectedItemUnitLimit = []
            }
        },
        selectedItemGroupLimit() {
            if(this.rowKolvoSumma=='radio-1') {
                try{
                    if(this.selectedItemGroupLimit.length != 0) {
                        //console.log(this.selectedItemGroupLimit)
                        this.selectedItemUnitLimit = this.UnitLimitReturn[0]
                    }
                } catch {
                    this.selectedItemUnitLimit = []
                }
            }
            
        },
        rowKolvoSumma() {
            if(this.rowKolvoSumma=='radio-1') {
                //console.log(this.UnitLimitReturn[0])
                this.selectedItemUnitLimit = this.UnitLimitReturn[0]
            }
            if(this.rowKolvoSumma=='radio-2') {
                //console.log(this.CurrencyLimitReturn[0])
                this.selectedItemCurrencyLimit = this.CurrencyLimitReturn[0]
            }
        },
        async selectedItemRegion() {
            console.log(this.selectedItemRegion)
            try {
                if(this.selectedItemRegion.id !== null) {
                    //console.log(this.selectedItemRegion.id)
                    const setting = {
                        gpnapi: this.$store.getters.gpn24,
                        sessionid: this.$store.getters.SessionId,
                        gpnurl: this.$store.getters.url24,
                        regionid: this.selectedItemRegion.id
                    }
                    await this.$store.dispatch('loadAzsList', setting)
                }
            } catch {
                this.selectedItemRegion = []
                const setting = {
                    gpnapi: this.$store.getters.gpn24,
                    sessionid: this.$store.getters.SessionId,
                    gpnurl: this.$store.getters.url24,
                    regionid: undefined
                }
                await this.$store.dispatch('loadAzsList', setting)
            }
        },
        selectedItemCountry() {
            console.log(this.selectedItemCountry)
        },
        selectedItemAzs() {
            console.log(this.selectedItemAzs)
        }
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Добавить' : 'Редактировать'
        },
        DataReturn() {
            if(this.usingRules == "limits") {
                return this.$store.getters.cardLimits
            } else {
                if(this.usingRules == "restrictions") {
                    return this.$store.getters.cardRestrictions
                } else {
                    if(this.usingRules == "RegionLimits") {
                        return this.$store.getters.cardRegionLimitList
                    } else {
                        return []
                    }
                }
            }

        },
        loadingF(){
            if(this.usingRules == "limits") {
                return this.$store.getters.loadingLimits
            } else {
                if(this.usingRules == "restrictions") {
                    return this.$store.getters.loadingRestrict
                } else {
                    if(this.usingRules == "RegionLimits") {
                        return this.$store.getters.loadingRegionLimit
                    } else {
                        return []
                    }
                }
            }
        },
        loadingAzs(){
            return this.$store.getters.loadingAzs
        },
        TypeLimitReturn(){
            //console.log(this.$store.getters.productTypeList)
            return this.$store.getters.productTypeList
        },
        GroupLimitReturn(){
            //console.log(this.$store.getters.productGroupList)
            try {
                if(this.selectedItemTypeLimit.id != null) {
                    //Топливо = 1-CK231
                    //console.log(this.selectedItemTypeLimit.id)
                    return this.$store.getters.productGroupListTypeFiltr(this.selectedItemTypeLimit.id)
                }
                else {
                    return []
                }
            } catch {
                return []
            }
        },
        UnitLimitReturn() {
            //console.log(this.selectedItemGroupLimit)
            try {
                if(this.selectedItemGroupLimit.unit != null) {
                    return this.$store.getters.UnitListByGroup(this.selectedItemGroupLimit.unit)
                }
                else {
                    return this.$store.getters.UnitList
                }
            } catch {
                return this.$store.getters.UnitList
            }
            
        },
        CurrencyLimitReturn() {
            //console.log(this.$store.getters.CurrencyListRur)
            return this.$store.getters.CurrencyListRur
            
            
        },
        CountryReturn() {
            return this.$store.getters.countryList
        },
        RegionReturn() {
            try{
                if(this.selectedItemCountry.id != null) {
                    return this.$store.getters.RegionListByCountry(this.selectedItemCountry.id)
                } else {
                    return []
                }
            } catch {
                return []
            }
            
        },
        AzsReturn() {
            //console.log(1)
            return this.$store.getters.azsList
        }
    }
  }
</script>
<style>

</style>