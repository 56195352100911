<template>
    <div>
    <alertMessage :DialogMsg="alertMessage.alertMessageShow" :DialogImgColor="alertMessage.alertImgColor" :DialogImg="alertMessage.alertImg" :DialogHeaderText="alertMessage.alertHeaderText" :DialogText="alertMessage.alertText"></alertMessage>
    <v-dialog v-model="dialog" persistent  :max-width="maxWidth">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">{{ formTitle }}</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout row wrap >
                        <v-flex :class="field.sizeField" sm v-for="(field, index) in dialogFields" :key="index" >
                            <v-text-field :ref="editedItem[field.model]" :rules="field.rulesField" :counter="field.counterField" :mask="field.maskField"
                                          :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" v-if="drawComponent && field.typeField=='text' &&field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" type="password" v-if="field.typeField=='pass' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" :rules="field.rulesField"  :counter="field.counterField" :mask="field.maskField" type="number" v-if="field.typeField=='number' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-text-field :readonly="field.readonly" v-model="editedItem[field.model]" :label="field.label" :rules="emailRules"  :counter="field.counterField" :mask="field.maskField" type="text" v-if="field.typeField=='email' && field.dialog.indexOf(formTitle)!=-1"></v-text-field>
                            <v-checkbox color="info" :label="field.label" v-model="editedItem[field.model]" v-if="field.typeField=='check' && field.dialog.indexOf(formTitle)!=-1"></v-checkbox>
                            <dadata :FullAddressIN = "addressSelected" v-if="drawComponent && field.typeField=='dadata' && field.dialog.indexOf(formTitle)!=-1"></dadata>
                            <bnkseek :BnkId="bnkID" v-if="drawComponent && field.typeField=='bnkseek' && field.dialog.indexOf(formTitle)!=-1"></bnkseek>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small dark color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small dark color="primary" @click.native="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-alert
            v-model="alert"
            color="error"
            icon="priority_high"
            outlined
            dismissible
    >
        {{ alertText }}
    </v-alert>

    <v-dialog v-model="dialogDel" persistent  max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline" style="color:#1976d2">Удаление</span>
            </v-card-title>
            <v-alert
                    v-model="alertDialog"
                    color="error"
                    icon="warning"
                    outlined
                    dismissible
            >
                {{ alertText }}
            </v-alert>
            <hr />
            <v-card-text>
                <span >Удалить запись - {{ typeof selected !== "undefined" ? selected.strShortName : '' }}?</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" @click.native="close">Отмена</v-btn>
                <v-btn small color="primary" @click.native="doDelete">Удалить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
              
    <v-container class="mainGridConteiner" :style="maxGridWidth">
        <v-layout justify-center >
            <v-card class="pa-3">
            <dx-data-grid :ref="gridRefName"
                            id="gridContainer"
                            :data-source="DataReturn"
                            :show-borders="true"
                            :hover-state-enabled="true"
                            :allow-column-reordering="allowColumnReordering"
                            :allow-column-resizing="allowColumnResizing"
                            :column-auto-width="columnAutoWidth"
                            :column-hiding-enabled="columnHidingEnabled"
                            :word-wrap-enabled="wordWrapEnabled"
                            @toolbar-preparing="onToolbarPreparing($event)"
                            key="id"
                            @selection-changed="onSelectionChanged"
                            
                >
                <DxExport
                    :enabled="false"
                    :allow-export-selected-data="true"
                    file-name="Transactions"
                />
                <dx-paging :page-size="pageSize"/>
                <dx-pager
                    :show-page-size-selector="showPageSizeSelector"
                    :allowed-page-sizes="allowedPageSizes"
                    :show-info="true"
                />

                <dx-selection mode="single" />
                <dx-group-panel :visible="groupPanelVisible" />
                <dx-load-panel :enabled="false" :visible="false" />
                <dx-filter-row :visible="filterRowVisible" />
                <dx-header-filter :visible="headerFilterVisible" />
                <dx-search-panel :visible="searchVisible" :width="searchWidth" placeholder="Поиск..." />

                <DxColumn v-for="(col, index) in columnSettingsIN" :key="index+'-column'" :width="col.colWidth" :data-field="col.colDataField" :caption="col.colCaption" :sort-order="col.sortOrder" :visible.sync="col.visible" :data-type="col.datatype" :format="col.format" :hiding-priority="col.hidingpriority" :group-index="col.groupindex">
                    <DxFormat
                        v-if="col.datatype=='number'"
                        type="fixedPoint"
                        :precision="2"
                    />
                </DxColumn>
                <!-- <DxColumn data-field="amount" caption="Kolvo" data-type="number" format=",###,###,###,##0.00">
                    <DxFormat
                        type="fixedPoint"
                        :precision="2"
                    />
                </DxColumn> -->
                

                <DxSummary v-if="summaryVisible" >
                    <DxGroupItem
                        column="time"
                        summary-type="count"
                        display-format="Транзакций: {0}"
                        value-format=",###,###,###,##0.00"
                    />
                    <DxGroupItem
                        :show-in-group-footer="false"
                        :align-by-column="true"
                        column="discount_cost"
                        summary-type="sum"
                        display-format="{0}"
                        value-format=",###,###,###,##0.00"
                    />
                    <DxGroupItem
                        :show-in-group-footer="false"
                        :align-by-column="true"
                        column="cost"
                        summary-type="sum"
                        display-format="{0}"
                        value-format=",###,###,###,##0.00"
                    />
                    <!-- <DxGroupItem
                        :show-in-group-footer="true"
                        column="cost"
                        summary-type="sum"
                        display-format="{0}"
                    /> -->
                    <DxGroupItem
                        :show-in-group-footer="false"
                        :align-by-column="true"
                        column="amount"
                        summary-type="sum"
                        display-format="{0}"
                        value-format=",###,###,###,##0.00"
                    />
                    <!-- <DxGroupItem
                        :show-in-group-footer="true"
                        column="amount"
                        summary-type="sum"
                        display-format="{0}"
                    /> -->
                    <!-- <DxGroupItem
                        :show-in-group-footer="true"
                        column="time"
                        display-format="Итого по группе:"
                    /> -->
                    <DxTotalItem
                        column="time"
                        display-format="Итого:"
                    />
                    <DxTotalItem
                        column="amount"
                        summary-type="sum"
                        display-format="{0}"
                        value-format=",###,###,###,##0.00"
                        
                    />
                    <DxTotalItem
                        column="cost"
                        summary-type="sum"
                        display-format="{0}"
                        value-format=",###,###,###,##0.00"

                    />
                    <DxTotalItem
                        column="discount_cost"
                        summary-type="sum"
                        display-format="{0}"
                        value-format=",###,###,###,##0.00"

                    />
                </DxSummary>
                <dx-sort-by-group-summary-info summary-item="count" />
                <div
                                slot="vProgressVuetify"
                                
                        >
                            <div>
                                <p style="margin: 0 4.5em 0em 0em;"></p>
                                <v-progress-circular
                                  :size="40"
                                  color="indigo"
                                  indeterminate
                                  v-if="loadingF"
                                ></v-progress-circular>
                            </div>
                        </div>
                        <div
                                slot="titleGroupVuetify"
                                
                        >
                            <div>
                                <v-chip outlined color="primary">
                                    <v-icon left>{{headerIcon}}</v-icon>{{headerText}}
                                </v-chip>
                                <div>
                                <p style="padding: 0 15em 0em 0;"></p>
                                </div>
                            </div>
                        </div>
                        <div
                                slot="GroupButtonVuetify"
                                
                        >
                            <div>
                                    <v-tooltip bottom class="pa-4">
                                         <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="refreshData()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="btnRefresh"
                                                    v-on="on"
                                                    
                                            >
                                                <v-icon>refresh</v-icon>
                                            </v-btn>
                                         </template>
                                         <span>Обновить</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="addItem"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnAdd"
                                                    v-on="on"
                                                    class="mx-2"
                                            >
                                                <v-icon>mdi-application-export</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Экспортировать в Excel</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="editItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnEdit"
                                                    v-on="on"
                                                    
                                            >
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Редактировать</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                    slot="activator"
                                                    @click="deleteItem()"
                                                    color="indigo"
                                                    dark
                                                    small
                                                    right
                                                    fab
                                                    v-if="BtnDelete"
                                                    v-on="on"
                                                    class="mx-2"
                                            >
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Удалить</span>
                                    </v-tooltip>
                                    
                            </div>
                        </div>
            </dx-data-grid >
             </v-card>
        </v-layout>
    </v-container>
</div>
</template>

<script>
    import Vue from 'vue'
    //import axios from 'axios'
    
    import Vuetify from 'vuetify'
    import 'material-design-icons-iconfont/dist/material-design-icons.css'
    import 'vuetify/dist/vuetify.min.css'
    import ru from 'vuetify/es5/locale/ru'
    import alertMessage from "@/components/alertMessage.vue"

    Vue.use(Vuetify, {
        lang: {
            locales: { 'ru': ru },
            current: 'ru'
        }
    })

   import {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxSummary,
    DxGroupItem,
    DxGroupPanel,
    DxHeaderFilter,
    DxSearchPanel,
    DxSortByGroupSummaryInfo,
    DxFilterRow,
    DxLoadPanel,
    DxPaging,
    DxPager,
    DxExport,
    DxTotalItem,
    DxFormat
} from 'devextreme-vue/data-grid';


import 'devextreme/dist/css/dx.common.css';



    export default {
        name: "sample",
        components: {
        DxDataGrid,
        DxColumn,
        DxSelection,
        DxSummary,
        DxGroupItem,
        DxGroupPanel,
        DxHeaderFilter,
        DxSortByGroupSummaryInfo,
        DxSearchPanel,
        DxFilterRow,
        DxLoadPanel,
        DxPaging,
        DxPager,
        alertMessage,
        DxExport,
        DxTotalItem,
        DxFormat
    },
    props: {
        GridRefName: {
            default () {
                return 'dataGrid'
            }
        },
        AllowColumnReordering: {
            default () {
                return true
            }
        },
        AllowColumnResizing: {
            default () {
                return true
            }
        },
        ColumnAutoWidth: {
            default () {
                return true
            }
        },
        ColumnHidingEnabled: {
            default () {
                return true
            }
        },
        PageSize: {
            default () {
                return 5
            }
        },
        ShowPageSizeSelector: {
            default () {
                return false
            }
        },
        AllowedPageSizes: {
            default () {
                return [ 5 ]
            }
        },
        HeaderText: {
            default () {
                return 'Вам нужно передать заголовок через Props'
            }
        },
        HeaderIcon: {
            default () {
                return ''
            }
        },
        ColumnSettingsIN: {
            default () {
                return []
            }
        },
        SummaryVisible: {
            default () {
                return false
            }
        },
        GroupSummarySettingsIN: {
            default () {
                return []
            }
        },
        GroupPanelVisible: {
            default () {
                return false
            }
        },
        FilterRowVisible: {
            default () {
                return false
            }
        },
        HeaderFilterVisible: {
            default () {
                return false
            }
        },
        SearchVisible: {
            default () {
                return false
            }
        },
        SearchWidth: {
            default () {
                return 300
            }
        },
        BtnRefresh: {
            default () {
                return false
            }
        },
        BtnAdd: {
            default () {
                return false
            }
        },
        BtnEdit: {
            default () {
                return false
            }
        },
        BtnDelete: {
            default () {
                return false
            }
        },
        BtnShipper: {
            default () {
                return false
            }
        },
        MaxWidth: {
            default () {
                return "600px"
            }
        },
        MaxGridWidth: {
            default () {
                return ""
            }
        },
        EditedItem: {
            default () {
                return [{
                    id: ''
                }]
            }
        },
        DefaultItem: {
            default () {
                return [{
                    id: ''
                }]
            }
        },
        EditedFields: {
            default () {
                return [{
                    model: ''
                }]
            }
        },
        AddFields: {
            default () {
                return [{
                    model: ''
                }]
            }
        },
        EndPoint: {
            default () {
                return ''
            }
        },
        ParentParams: {
            default () {
                return null
            }
        },
        DataReturnF: {
            default () {
                return null
            }
        },
        WordWrapEnabled: {
            default () {
                return false
            }
        },
        UsingRules: {
            default () {
                return ''
            }
        },
        AllCard: {
            default () {
                return false
            }
        }
    },
        data() {
            return {
            maxGridWidth: '',
            allCard: false,
            allowColumnReordering: this.AllowColumnReordering,
            allowColumnResizing: this.AllowColumnResizing,
            columnAutoWidth: this.ColumnAutoWidth,
            columnHidingEnabled: this.ColumnHidingEnabled,
            pageSize: this.PageSize,
            showPageSizeSelector: this.ShowPageSizeSelector,
            allowedPageSizes: this.AllowedPageSizes,
            drawComponent: true,
            gridRefName: this.GridRefName,
            expanded: true,
            alert: false,
            alertDialog: false,
            alertText: '',
            dialog: false,
            dialogDel: false,
            dialogSetShipper: false,
            headerText: this.HeaderText,
            headerIcon: this.HeaderIcon,
            columnSettingsIN: this.ColumnSettingsIN,
            summaryVisible: this.SummaryVisible,
            groupSummarySettingsIN: this.GroupSummarySettingsIN,
            groupPanelVisible: this.GroupPanelVisible,
            filterRowVisible: this.FilterRowVisible,
            headerFilterVisible: this.HeaderFilterVisible,
            searchVisible: this.SearchVisible,
            searchWidth: this.SearchWidth,
            btnRefresh: this.BtnRefresh,
            btnAdd: this.BtnAdd,
            btnEdit: this.BtnEdit,
            btnDelete: this.BtnDelete,
            btnShipper: this.BtnShipper,
            loading: false,
            selected: '',
            dataItems: '',
            dataReturn: '',
            editedIndex: -1,
            editedItem: {},
            defaultItem: {},
            editedFields: [],
            addFields: [],
            dialogFields: [],
            endPoint: '',
            parentParams: this.ParentParams,
            maxWidth: "",
            bnkID: '',
            addressSelected: '',
            alertMessage: {alertMessageShow: false, alertImgColor: "error", alertImg: "error_outline", alertHeaderText: 'Ошибка', alertText: 'Ошибка'},
            shipperMailShow: true,
            wordWrapEnabled: false,
            usingRules: '',
            emailRules: [ 
                            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail введен не верно'
                        ]
        }
    },
    created() {

    },
    beforeMount() {
        this.allCard = this.AllCard
        //console.log(this.AllCard)
        this.gridRefName = this.GridRefName
        this.allowColumnReordering = this.AllowColumnReordering
        this.allowColumnResizing = this.AllowColumnResizing
        this.columnAutoWidth = this.ColumnAutoWidth
        this.columnHidingEnabled = this.ColumnHidingEnabled
        this.pageSize = this.PageSize
        this.showPageSizeSelector = this.ShowPageSizeSelector
        this.allowedPageSizes = this.AllowedPageSizes
        this.headerText = this.HeaderText
        this.headerIcon = this.HeaderIcon
        this.columnSettingsIN = this.ColumnSettingsIN
        this.groupSummarySettingsIN = this.GroupSummarySettingsIN
        this.groupPanelVisible = this.GroupPanelVisible
        this.filterRowVisible = this.FilterRowVisible
        this.headerFilterVisible = this.HeaderFilterVisible
        this.searchVisible = this.SearchVisible
        this.searchWidth = this.SearchWidth
        this.summaryVisible = this.SummaryVisible
        this.editedItem = this.EditedItem
        this.defaultItem = this.DefaultItem
        this.editedFields = this.EditedFields
        this.addFields = this.AddFields
        this.endPoint = this.EndPoint
        this.parentParams = this.ParentParams
        this.btnRefresh = this.BtnRefresh
        this.btnAdd = this.BtnAdd
        this.btnEdit = this.BtnEdit
        this.btnDelete = this.BtnDelete
        this.btnShipper = this.BtnShipper
        this.maxWidth = this.MaxWidth
        this.maxGridWidth = this.MaxGridWidth
        this.dataReturn = this.DataReturnF
        this.wordWrapEnabled = this.WordWrapEnabled
        this.usingRules = this.UsingRules
    },
    methods: {
        reset: function() {
            var vm = this;
            vm.drawComponent = false;
            Vue.nextTick(function() {
                vm.drawComponent = true;
            });
        },
        doAlertMessage(Show, ImgColor, Img, HeaderText, MsgText) {
            this.alertMessage.alertMessageShow = Show
            if(ImgColor != ''){ this.alertMessage.alertImgColor = ImgColor }
            if(Img != ''){ this.alertMessage.alertImg = Img }
            if(HeaderText != ''){ this.alertMessage.alertHeaderText = HeaderText }
            if(MsgText != ''){ this.alertMessage.alertText = MsgText }
        },
        onToolbarPreparing(e) {
            e.toolbarOptions.items.unshift({
                    location: 'after',
                    template: 'vProgressVuetify'
                }, {
                location: 'after',
                template: 'GroupButtonVuetify'
                }, {
                location: 'before',
                template: 'titleGroupVuetify'
            });
        },
        refreshData(){
            //console.log(this.allCard)
            if(this.usingRules == "loadTransactionListF") {
                if(this.$store.getters.GetAllCard == false){
                    const setting = {
                            cardid: this.$store.getters.CardNumber,
                            dateStart: this.$store.getters.dateStart,
                            dateEnd: this.$store.getters.dateEnd
                        }
                    this.$store.dispatch('loadTransactionListF', setting)
                    
                }
                if(this.$store.getters.GetAllCard == true) {
                    const setting = {
                        cardid: this.$store.getters.cardsList,
                        dateStart: this.$store.getters.dateStart,
                        dateEnd: this.$store.getters.dateEnd
                    }
                    //console.log(setting)
                    this.$store.dispatch('loadTransactionListFAll', setting)
                }
            }
        },
        addItem(){
            //console.log()
            this.$refs[this.gridRefName].instance.exportToExcel()
                   
        },
        onSelectionChanged({ selectedRowsData }) {
            this.selected = selectedRowsData[0]
            //console.log(this.selected)
            
        },
        close () {
            

        },
        deleteItem () {
            
        },
        doDelete(){
            

        },
        editItem () {
            
        },
        save () {
           
            
        }
    },
    watch: {
        DataReturn() {
            if(this.$store.getters.GetAllCard == false){
                if(this.columnSettingsIN[10] !== undefined){
                    this.columnSettingsIN[10].visible = false
                    this.$refs[this.gridRefName].instance.columnOption("card_number", "groupIndex", undefined);
                    this.groupPanelVisible = true
                }
            }
            if(this.$store.getters.GetAllCard == true){
                if(this.columnSettingsIN[10] !== undefined){
                    this.columnSettingsIN[10].visible = true
                    this.$refs[this.gridRefName].instance.columnOption("card_number", "groupIndex", 0);
                    this.groupPanelVisible = true
                }
            }
        }
    },
    mounted() {
        
        this.$root.$on('closeAlertMessage', (text) => {
            this.alertMessage.alertMessageShow = text
        })
        this.dialogFields = this.addFields
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'Добавить' : 'Редактировать'
        },
        DataReturn() {
            if(this.$store.getters.TransactionsListF.length == 0)
            {
                 this.$store.commit('setLoading2', false)
            }
            return this.$store.getters.TransactionsListF
        },
        loadingF(){
            return this.$store.getters.loading2
        }
    }
  }
</script>
<style>

</style>