
import store from './store.js'
import axios from 'axios'
import router from '../router/router.js'
//const uuid = require('uuid');

import UserSettings from './userSettings_help'

export default {
    state: {
        settings: [],
        groupSettings: '',
        idSettings: ''
    },
    mutations: {
        loadSettings (state, payload) {
            state.settings = payload
        },
        editSettings (state, {strINN, strShortName, boolIsAdmin, strCardGroupName, numProcPrice, id}) {
          const setting = state.settings.find(t => {
            //console.log(t)
            return t.idfr === id
          })
          setting.strINN = strINN
          setting.strShortName = strShortName
          setting.boolIsAdmin = boolIsAdmin
          setting.strCardGroupName = strCardGroupName
          setting.numProcPrice = numProcPrice
        },
        newSettings (state, payload) {
            state.settings.push(payload)
        },
        loadCardGroupFromSettings (state, payload) {
            state.groupSettings = payload
        },
        loadIdFromSettings (state, payload) {
            state.idSettings = payload
        }  
    },
    actions: {
        async loadSettings ({commit}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)
                //console.log(11)
                /* const setting = await firebase.database().ref('userSettings').once('value')
                const settings = setting.val()
                //console.log(settings)
                const settingsArray = []
                Object.keys(settings).forEach(key => {
                    const s = settings[key]
                    settingsArray.push(
                        new UserSettings(
                            s.strINN,
                            s.strShortName,
                            s.boolIsAdmin,
                            s.strCardGroupName,
                            s.numProcPrice,
                            s.user,
                            key
                        )
                    )
                }) */
                //console.log(store.getters.user)
                let dataTr = [
                    {"proc":'loadDictionary_userSettingsAllPG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const settings = res.data
                    //console.log(settings)
                    const settingsArray = []
                    Object.keys(settings).forEach(key => {
                        const s = settings[key]
                        settingsArray.push(
                            new UserSettings(
                                s.strINN,
                                s.strShortName,
                                s.boolIsAdmin,
                                s.strCardGroupName,
                                s.numProcPrice,
                                s.username,
                                null,
                                s.idfr,
                                key
                            )
                        )
                    })

                    //console.log(settingsArray[0].strCardGroupName)

                    commit('loadSettings', settingsArray)
                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    commit('loadSettings', [])
                    commit('setLoading', false)
                    commit('setError', response.message)
                    reject(response)
                    commit('setUser', null)
                    router.push('/')
                });
            }) 
        },
        async loadCardGroupFromSettings ({commit}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)
                //console.log(22)
                let dataTr = [
                    {"proc":'loadDictionary_userSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const settings = res.data
                    //console.log(settings)
                    const settingsArray = []
                    Object.keys(settings).forEach(key => {
                        const s = settings[key]
                        settingsArray.push(
                            new UserSettings(
                                s.strINN,
                                s.strShortName,
                                s.boolIsAdmin,
                                s.strCardGroupName,
                                s.numProcPrice,
                                s.user,
                                null,
                                s.idfr,
                                key
                            )
                        )
                    })

                    //console.log(settingsArray[0].strCardGroupName)
                    //console.log(settingsArray[0].idfr)
                    commit('loadCardGroupFromSettings', settingsArray[0].strCardGroupName)
                    commit('loadIdFromSettings', settingsArray[0].idfr)
                    /* var userAuthId = firebase.auth().currentUser.uid;
                    //console.log(userAuthId)

                    var ref = firebase.database().ref("userSettings");
                    ref.orderByChild("user").equalTo(userAuthId).on("child_added", function(snapshot) {
                        //console.log(snapshot.val());
                        commit('loadCardGroupFromSettings', snapshot.val().strCardGroupName)
                        var key = snapshot.key;
                        //console.log(key)
                        commit('loadIdFromSettings', key)
                    }); */

                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    commit('loadCardGroupFromSettings', [])
                    commit('loadIdFromSettings', '')
                    commit('setLoading', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            }) 

        },
        async loadIdFromSettings ({commit}) {
            
            /* try {
                var userAuthId = firebase.auth().currentUser.uid;
                //console.log(userAuthId)

                var ref = firebase.database().ref("userSettings");
                ref.orderByChild("user").equalTo(userAuthId).on("child_added", function(snapshot) {
                    var key = snapshot.key;
                    //console.log(key)
                    commit('loadIdFromSettings', key)
                });

                commit('setLoading', false)
            } catch (error) {
                commit('loadIdFromSettings', '')
                commit('setLoading', false)
                commit('setError', error.message)
                //throw error
            } */
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)
                //console.log(44)
                let dataTr = [
                    {"proc":'loadDictionary_userSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const settings = res.data
                    //console.log(settings)
                    const settingsArray = []
                    Object.keys(settings).forEach(key => {
                        const s = settings[key]
                        settingsArray.push(
                            new UserSettings(
                                s.strINN,
                                s.strShortName,
                                s.boolIsAdmin,
                                s.strCardGroupName,
                                s.numProcPrice,
                                s.user,
                                null,
                                s.idfr,
                                key
                            )
                        )
                    })

                    //console.log(settingsArray[0].strCardGroupName)
                    //console.log(settingsArray[0].idfr)
                    //commit('loadCardGroupFromSettings', settingsArray[0].strCardGroupName)
                    commit('loadIdFromSettings', settingsArray[0].idfr)
                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    commit('loadIdFromSettings', '')
                    commit('setLoading', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })

        },
        async editSettings ({commit}, {strINN, strShortName, boolIsAdmin, strCardGroupName, numProcPrice, id}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)
                /* try {
                // Update title & descr
                await firebase.database().ref('userSettings').child(id).update({
                    strINN,
                    strShortName,
                    boolIsAdmin,
                    strCardGroupName,
                    numProcPrice
                })
                // Send mutation
                commit('editSettings', {strINN, strShortName, boolIsAdmin, strCardGroupName, numProcPrice, id})
        
                commit('setLoading', false)
                } catch (error) {
                commit('setLoading', false)
                commit('setError', error.message)
                throw error
                } */
                //console.log(strShortName)
                let dataTr = [
                    {"proc":'updateDictionary_userSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "strINN": strINN,"strShortName": strShortName,"boolIsAdmin": boolIsAdmin,"strCardGroupName": strCardGroupName,
                    "numProcPrice": numProcPrice,"idfr": id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(res)
                    commit('editSettings', {strINN, strShortName, boolIsAdmin, strCardGroupName, numProcPrice, id})
                    commit('setLoading', false)
                    resolve();
                })
                .catch((response) => {
                    commit('setLoading', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        },
        async deleteSettings ({commit}, id) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)
                /* try {
                await firebase.database().ref('userSettings').child(id).remove()
        
                commit('setLoading', false)
                } catch (error) {
                commit('setLoading', false)
                commit('setError', error.message)
                throw error
                } */
                let dataTr = [
                    {"proc":'deleteDictionary_userSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "idfr": id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    // Send mutation
                   /*  commit('newSettings', {
                        ...newUserSettings
                    }) */

                    
            
                    commit('setLoading', false)
                    resolve(res);
                })
                .catch((response) => {
                    commit('setLoading', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        },
        async newSettings ({commit}, payload) {
            return new Promise((resolve, reject) => {
                //console.log('13 ' + getters.UserId)
                commit('clearError')
                commit('setLoading', true)
            
                /* try {
                    // Used helped class
                    
                    const newUserSettings = new UserSettings(
                        payload.strINN,
                        payload.strShortName,
                        payload.boolIsAdmin,
                        payload.strCardGroupName,
                        payload.numProcPrice,
                        payload.user,
                        payload.idfr
                    )
                    //console.log(newUserSettings)
                    // Push newTask
                    const setting = await firebase.database().ref('userSettings').push(newUserSettings)
            
                    // Send mutation
                    commit('newSettings', {
                    ...newUserSettings,
                    id: setting.key
                    })
            
                    commit('setLoading', false)
                } catch (error) {
                    commit('setLoading', false)
                    commit('setError', error.message)
                    throw error
                } */
                const newUserSettings = new UserSettings(
                    payload.strINN,
                    payload.strShortName,
                    payload.boolIsAdmin,
                    payload.strCardGroupName,
                    payload.numProcPrice,
                    payload.username,
                    payload.userps
                )
                //console.log(newUserSettings)
                let dataTr = [
                    {"proc":'insertDictionary_userSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "newUserSettings": newUserSettings}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    // Send mutation
                   /*  commit('newSettings', {
                        ...newUserSettings
                    }) */

                    
            
                    commit('setLoading', false)
                    resolve(res);
                })
                .catch((response) => {
                    commit('setLoading', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })

            })
        }
    },
    getters: {
        settings (state) {
            return state.settings
        },
        groupSettings (state) {
            return state.groupSettings
        },
        idSettings (state) {
            return state.idSettings
        }
    }
}