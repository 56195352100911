import axios from 'axios'
import date from 'date-and-time';
import store from './store.js'
import router from '../router/router.js'


export default {
    state: {
        azsList: []
    },
    mutations: {
        loadAzsList (state, payload) {
            state.azsList = payload
        }
    },
    actions: {
        async loadAzsList ({commit}, { gpnapi, sessionid, gpnurl, regionid }) {
            commit('clearError')
            commit('setLoadingAzs', true)
            if(regionid != undefined){
                console.log(regionid)
                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                /* axios.get(gpnurl + 'vip/v1/AZS?filter={"country":["RUS"],"region":["' + regionid + '"],"owntype":["Own GPN"],"status":["257"]}', {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'AZS_filter', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "sessionid":sessionid, "regionid":regionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((response) => {
                    console.log(response.data.data.result)
                    commit('loadAzsList', response.data.data.result)
                    commit('setLoadingAzs', false)
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    commit('loadAzsList', [])
                    commit('setLoadingAzs', false)
                    commit('setError', response)

                    

                    commit('setUser', null)
                    router.push('/')
                });

            }
            else {
                commit('loadAzsList', [])
                commit('setLoadingAzs', false)
            }   

        }


        

    },
    getters: {
        azsList (state) {
            return state.azsList
        }
    }
}