import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './user'
import common from './common'
import userSettings from './userSettings'
import cardGroupList from './cardGroupList'
import cardsList from './cardsList'
import transactionsList from './transactionsList'
import transactionsListF from './transactionsListF'
import cardStatus from './cardStatus'
import cardLimitsList from './cardLimitsList'
import productGroupList from './productGroupList'
import unitList from './unitList'
import cardRestrictionsList from './cardRestrictionsList'
import countryList from './countryList'
import currencyList from './currencyList'
import productTypeList from './productTypeList'
import productList from './productList'
import regionList from './regionList'
import azsList from './azsList'
import cardRegionLimitList from './cardRegionLimitList'
import userProcSettings from './userProcSettings'
import userProductSettings from './userProductSettings'
import opti24settings from './opti24settings'

import { encryptSTRJ, decryptSTRJ } from './unitBase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //demo
    //user24: 'demo',
    //pass24: 'demo',
    //url24: 'https://apidemo.opti-24.com/',
    //url24tr: 'https://apidemo.opti-24.com/',
    //url24sr: 'https://us-central1-opti24-335bb.cloudfunctions.net/LoadOpti241Transport',
    //gpn24: 'GPN.3ce7b860ece5758d1d27c7f8b4796ea79b33927e.c4ac404d4d476a779b46425d3c23d9416ded0db6',
    //contractId: '1-2SY666F',
    //real
    user24: '',
    pass24: '',
    url24: '',
    url24tr: '',
    url24sr: '',
    gpn24: '',
    contractId: '',
    UserId: '',
    LastContract: '',
    SessionId: '',
    CardID: '',
    CardNumber: '',
    UserName: '',
    IsAdmin: false,
    dateStart: '',
    dateEnd: '',
    proc: '',
    CardGroupID: '',
    GetAllCard: false,
    UserMail: '',
    UserINN: '',
    UserSettingsId: '',
    UserSettingsName: '',
    ContractsList: [],
    AlertOpti24False: false
  },
  mutations: {
    SetAlertOpti24False(state, AlertOpti24False) {
      state.AlertOpti24False = AlertOpti24False
    },
    SetContractsList(state, ContractsList) {
      state.ContractsList = ContractsList
    },
    Setuser24(state, user24) {
      state.user24 = encryptSTRJ(user24)
    },
    Seturl24sr(state, url24sr) {
      state.url24sr = encryptSTRJ(url24sr)
    },
    Seturl24(state, url24) {
      state.url24 = encryptSTRJ(url24)
    },
    Seturl24tr(state, url24tr) {
      state.url24tr = encryptSTRJ(url24tr)
    },
    Setpass24(state, pass24) {
      state.pass24 = encryptSTRJ(pass24)
    },
    Setgpn24(state, gpn24) {
      state.gpn24 = encryptSTRJ(gpn24)
    },
    SetUserId(state, UserId) {
      state.UserId = UserId
    },
    SetUserMail(state, UserMail) {
      state.UserMail = UserMail
    },
    SetGetAllCard(state, GetAllCard) {
      state.GetAllCard = GetAllCard
    },
    SetLastContract(state, LastContract) {
      state.LastContract = LastContract
    },
    SetSessionId(state, SessionId) {
      state.SessionId = encryptSTRJ(SessionId)
    },
    SetCardID(state, CardID) {
      state.CardID = CardID
    },
    SetCardNumber(state, CardNumber) {
      state.CardNumber = CardNumber
    },
    SetUserName(state, UserName) {
      state.UserName = UserName
    },
    SetAdmin(state, IsAdmin) {
      state.IsAdmin = IsAdmin
    },
    SetDateEnd(state, dateEnd) {
      state.dateEnd = dateEnd
    },
    SetDateStart(state, dateStart) {
      state.dateStart = dateStart
    },
    SetProc(state, proc) {
      state.proc = proc
    },
    SetCardGroupID(state, CardGroupID) {
      state.CardGroupID = CardGroupID
    },
    SetContractId(state, contractId) {
      state.contractId = contractId
    },
    SetUserINN(state, UserINN) {
      state.UserINN = UserINN
    },
    SetUserSettingsId(state, UserSettingsId) {
      state.UserSettingsId = UserSettingsId
    },
    SetUserSettingsName(state, UserSettingsName) {
      state.UserSettingsName = UserSettingsName
    },
  },
  getters: {
      AlertOpti24False: state => state.AlertOpti24False,
      ContractsList: state => state.ContractsList,
      user24: state => decryptSTRJ(state.user24),
      url24sr: state => decryptSTRJ(state.url24sr),
      url24: state => decryptSTRJ(state.url24),
      url24tr: state => decryptSTRJ(state.url24tr),
      pass24: state => decryptSTRJ(state.pass24),
      gpn24: state => decryptSTRJ(state.gpn24),
      UserId: state => state.UserId,
      LastContract: state => state.LastContract,
      SessionId: state => decryptSTRJ(state.SessionId),
      CardID: state => state.CardID,
      CardNumber: state => state.CardNumber,
      UserName: state => state.UserName,
      IsAdmin: state => state.IsAdmin,
      dateStart: state => state.dateStart,
      dateEnd: state => state.dateEnd,
      procent: state => state.proc,
      CardGroupID: state => state.CardGroupID,
      GetAllCard: state => state.GetAllCard,
      UserMail: state => state.UserMail,
      contractId: state => state.contractId,
      UserINN: state => state.UserINN,
      UserSettingsId: state => state.UserSettingsId,
      UserSettingsName: state => state.UserSettingsName
  },
  actions: {
  },
  modules: {
    user,
    common,
    userSettings,
    cardGroupList,
    cardsList,
    transactionsList,
    transactionsListF,
    cardLimitsList,
    cardStatus,
    productGroupList,
    unitList,
    cardRestrictionsList,
    countryList,
    currencyList,
    productTypeList,
    productList,
    regionList,
    azsList,
    cardRegionLimitList,
    userProcSettings,
    userProductSettings,
    opti24settings
  },
  plugins: [createPersistedState()]
})
