import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import UnitList from './unitList_help'
import store from './store.js'

export default {
    state: {
        unitList: [],
    },
    mutations: {
        loadUnitList (state, payload) {
            state.unitList = payload
        },
    },
    actions: {
        async loadUnitList ({commit}, { gpnapi, sessionid, gpnurl }) {
            commit('clearError')
            commit('setLoading', true)

            const now = new Date();
            const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            
            axios.get(gpnurl + 'vip/v1/getDictionary?name=Unit', {
                headers: {
                    'api_key': gpnapi,
                    'session_id': sessionid,
                    'date_time': dt
                }
            })
            .then((response) => {
                console.log(response.data.data.result)
                commit('loadUnitList', response.data.data.result)
                commit('setLoading', false)
            })
            .catch((response) => {
                var err = Object.assign({}, response)
                console.log('error', err.response);

                commit('loadUnitList', [])
                commit('setLoading', false)
                commit('setError', response)

                if(err.response.status == '403')
                {
                    commit('setUser', null)
                    router.push('/')
                }
                if(err.response.status == '401')
                {
                    commit('setUser', null)
                    router.push('/')
                }
            });

            

        },
        async loadUnitListDB ({commit}) {
            return new Promise((resolve, reject) => {
            commit('clearError')
            commit('setLoading', true)

            let dataTr = [
                {"proc":'loadDictionary_UnitPG', "uid": store.getters.userUID, "uid2": store.getters.user.id}]
            axios({
                method: 'POST',
                headers: {
                    'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                    'Content-Type': 'application/json'
                },
                url: store.getters.url24sr,
                //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                data: dataTr

            })
            .then((res) => {
                //const unit = await firebase.database().ref('unitList').once('value')
                const units = res.data
                
                const unitsArray = []
                Object.keys(units).forEach(key => {
                    const s = units[key]
                    unitsArray.push(
                        new UnitList(
                            s.id,
                            s.value,
                            s.last_update,
                            s.udt_flag,
                            s.deleted
                        )
                    )
                })
                //console.log(unitsArray)
                commit('loadUnitList', unitsArray)
                commit('setLoading', false)
                resolve();
            })
            .catch((response) => {
                var err = Object.assign({}, response)
                console.log('error', err.response);
                commit('loadUnitList', [])
                commit('setLoading', false)
                commit('setError', response)
                if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            }) 

        },
        async deleteUnitListDB ({commit}) {
            return new Promise((resolve) => {
                commit('clearError')
                commit('setLoading', false)
                    resolve();
                /* firebase.database().ref('unitList').remove()
                .then(() => {
                    // http success, call the mutator and change something in state
                    commit('setLoading', false)
                    resolve();  // Let the calling function know that http is done. You may send some data back
                }, error => {
                    // http failed, let the calling function know that action did not work out
                    commit('setLoading', false)
                    commit('setError', error)
                    reject(error);
                }) */
            })

            

        },
        async insertUnitList ({commit}, { gpnapi, sessionid, gpnurl }) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading', true)

                const now = new Date();
                const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
                
                /* axios.get(gpnurl + 'vip/v1/getDictionary?name=Unit', {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'getDictionary_UnitPG', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(response.data.data.result)
                    
                    //const unitListGet = response.data.data.result
                    //const cardSatatusEmpty = []
                    //firebase.database().ref('cardStatus').remove()
                    //Object.keys(unitListGet).forEach(key => {
                    //    const s = unitListGet[key]
                    //    firebase.database().ref('unitList').push(s)
                    //})
                    //firebase.database().ref('cardStatus').push(response.data.data.result)
                    //commit('loadCardStatus', response.data.data.result)
                    commit('setLoading', false)
                    resolve()
                })
                .catch((response) => {
                    var err = Object.assign({}, response)
                    console.log('error', err.response);

                    //commit('loadCardStatus', [])
                    commit('setLoading', false)
                    commit('setError', response)
                    if(err.response.status == '400')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    reject(response)
                });

            })

        },
    },
    getters: {
        UnitList (state) {
            return state.unitList
        },
        UnitListByGroup (state) {
            return unit => state.unitList.filter(name => {
                return name.id == unit
            })
        }
    }
}