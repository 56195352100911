import axios from 'axios'
import date from 'date-and-time';

import router from '../router/router.js'
import TransactionsList from './transactionsList_help'
import store from './store.js'

export default {
    state: {
        transactionslist: [],
    },
    mutations: {
        loadTransactionsList (state, payload) {
            state.transactionslist = payload
        },
    },
    actions: {
        async loadTransactionList ({commit}, { gpnapi, sessionid, contractid, cardid, gpnurl }) {
            commit('clearError')
            commit('setLoading2', true)

            const now = new Date();
            const dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
            const TransactionsListArr = []

            if (typeof cardid !== "undefined") {
                /* await axios.get(gpnurl + '/vip/v1/transactions?contract_id=' + contractid + '&card_id=' + cardid + '&count=10', {
                    headers: {
                        'api_key': gpnapi,
                        'session_id': sessionid,
                        'date_time': dt
                    }
                }) */
                let dataTr = [
                    {"proc":'transactions', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "contractid":contractid, "cardid":cardid, "sessionid":sessionid, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                    //console.log(dataTr)
                await axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(async (response) => {
                    //console.log(response);
                    //console.log(response.data.data.result)

                    //var settings = store.getters.settings
                    //console.log(settings)
                    //var NumProc = settings.find(o => o.user === store.getters.user.id);
                    //console.log(NumProc.numProcPrice)
                    //var NewPrice = (NumProc.numProcPrice/100) + 1
                    //console.log(NewPrice)

                    //const setting = response.data.data.result
                    const transactions = response.data.data.result
                    
                    //Object.keys(transactions).forEach(key => {
                    let nodes = Object.keys(transactions)
                    for(let key=0; key < nodes.length; key++) {
                        const s = transactions[key]
                        //console.log(s)
                        const transaction_items = s.transaction_items
                        //console.log(settings1)
                        if(transaction_items != null){
                            //Object.keys(transaction_items).forEach(key1 => {
                                let nodes1 = Object.keys(transaction_items)
                                for(let key1=0; key1 < nodes1.length; key1++) {
                                    const s1 = transaction_items[key1]
                                    var Tochka = ''
                                    /* await axios.get(gpnurl + '/vip/v1/AZS?id=' + s.service_center, {
                                        headers: {
                                            'api_key': gpnapi,
                                            'session_id': sessionid,
                                            'date_time': dt
                                        }
                                    }) */
                                    dataTr = [
                                        {"proc":'AZS', "gpnapi":gpnapi, "dt":dt, "gpnurl":gpnurl, "sessionid":sessionid, "service_center":s.service_center, "uid": store.getters.userUID, "uid2": store.getters.user.id}]
                                    
                                    await axios({
                                        method: 'POST',
                                        headers: {
                                            'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                                            'Content-Type': 'application/json'
                                        },
                                        url: store.getters.url24sr,
                                        //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                                        data: dataTr
                    
                                    })
                                    .then(async (response) => {
                                        Tochka = response.data.data.result.searchTxt
                                        var azsstr = ''
                                        if(response.data.data.result.POIType === 'AZS'){
                                            azsstr = 'АЗС '
                                        }
                                        azsstr = azsstr + response.data.data.result.address.city + ' ' + response.data.data.result.address.street + ' ' + response.data.data.result.address.house
                                        if(response.data.data.result.address.building !== ''){
                                            azsstr = azsstr + '/' + response.data.data.result.address.building
                                        }
                                        azsstr = azsstr + ' ' + response.data.data.result.address.kmRoad 
                                        //console.log(response.data.data.result)
                                        //console.log(azsstr)
                                        Tochka = azsstr
                                        var NumFixPrice = Object.assign({},store.getters.userProductSettings.find(o => o.dtDateStart <= s.time.substr(0, 10) && o.dtDateEnd >= s.time.substr(0, 10) && o.strName.trim() == s1.product.trim()));
                                        var NewFixPriceGet = NumFixPrice.numPrice
                                        //console.log(NewFixPriceGet)
                                        if(NumFixPrice.numPrice !== undefined) {
                                            TransactionsListArr.push(
                                                new TransactionsList(
                                                    s1.id,
                                                    s1.product,
                                                    s1.amount,
                                                    s1.cost,
                                                    NewFixPriceGet,
                                                    (NewFixPriceGet * s1.amount).toFixed(2),
                                                    s1.transaction,
                                                    s1.unit,
                                                    s1.price,
                                                    s.card_number,
                                                    Tochka,
                                                    s.currency,
                                                    s.time,
                                                    s.request.type,
                                                    s.request.name
                                                    
                                                )
                                            )
                                        }
                                        else
                                        {
                                            var NumProc = Object.assign({},store.getters.userProcSettings.find(o => o.dtDateStart <= s.time.substr(0, 10) && o.dtDateEnd >= s.time.substr(0, 10)));
                                            var Proc = 0
                                            if(NumProc.numProcPrice !== undefined) {
                                                Proc = NumProc.numProcPrice
                                            }
                                            var NewPrice = (Proc/100) + 1
                                            TransactionsListArr.push(
                                                new TransactionsList(
                                                    s1.id,
                                                    s1.product,
                                                    s1.amount,
                                                    s1.cost,
                                                    (s1.price * NewPrice).toFixed(2),
                                                    (s1.cost * NewPrice).toFixed(2),
                                                    s1.transaction,
                                                    s1.unit,
                                                    s1.price,
                                                    s.card_number,
                                                    Tochka,
                                                    s.currency,
                                                    s.time,
                                                    s.request.type,
                                                    s.request.name
                                                    
                                                )
                                            )
                                        }
                                    })

                                    
                                
                                
                            }
                        }
                    }

                    //console.log(TransactionsListArr)

                    
                    commit('loadTransactionsList', TransactionsListArr)
                    commit('setLoading2', false)
                })
                .catch((response) => {
                    //console.log(response)
                    var err = Object.assign({}, response)
                    console.log('error', err.response);
                                    
                    commit('loadTransactionsList', [])
                    commit('setLoading', false)
                    commit('setError', response)

                    if(err.response.status == '403')
                    {
                        commit('setUser', null)
                        router.push('/')
                    }
                    if(err.response.status == '401')
                    {
                        commit('setUser', null)
                        router.push('/')
                    } 
                });
        }
        else {
            //console.log('88')
            commit('loadTransactionsList', [])
            commit('setLoading2', false)
        }

            

        },
        
    },
    
    getters: {
        TransactionsList (state) {
            return state.transactionslist
        },
    }
}