
import store from './store.js'
import axios from 'axios'

import UserProcSettings from './userProcSettings_help'


export default {
    state: {
        procSettings: [],
        userProcSettings: [],
    },
    mutations: {
        loadProcSettings (state, payload) {
            state.procSettings = payload
        },
        loadUserProcSettings (state, payload) {
            state.userProcSettings = payload
        },
        editProcSettings (state, {IDSettings, numProcPrice, dtDateStart, dtDateEnd, id}) {
          const procSetting = state.procSettings.find(t => {
            return t.id === id
          })
          //console.log(procSetting)
          procSetting.IDSettings = IDSettings
          procSetting.numProcPrice = numProcPrice
          procSetting.dtDateStart = dtDateStart
          procSetting.dtDateEnd = dtDateEnd
        },
        newProcSettings (state, payload) {
            state.procSettings.push(payload)
        }
    },
    actions: {
        async loadProcSettings ({commit}, IDSettings) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
                /* try {
                    const procSetting = await firebase.database().ref('userProcSettings').orderByChild("IDSettings").equalTo(IDSettings).once('value')
                    const procSettings = procSetting.val()
                    //console.log(procSettings)
                    const procSettingsArray = []
                    Object.keys(procSettings).forEach(key => {
                        const s = procSettings[key]
                        procSettingsArray.push(
                            new UserProcSettings(
                                s.IDSettings,
                                s.numProcPrice,
                                s.dtDateStart,
                                s.dtDateEnd,
                                key
                            )
                        )
                    })
                    //console.log(procSettingsArray)
                    commit('loadProcSettings', procSettingsArray)
                    commit('setLoading2', false)
                } catch (error) {
                    commit('loadProcSettings', [])
                    commit('setLoading2', false)
                    commit('setError', error.message)
                    //throw error
                } */
                let dataTr = [
                    {"proc":'loadDictionary_ProcSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "IDSettings":IDSettings}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const procSettings = res.data
                    //console.log(procSettings)
                    const procSettingsArray = []
                    Object.keys(procSettings).forEach(key => {
                        const s = procSettings[key]
                        procSettingsArray.push(
                            new UserProcSettings(
                                s.IDSettings,
                                s.numProcPrice,
                                s.dtDateStart,
                                s.dtDateEnd,
                                s.idfr
                            )
                        )
                    })
                    //console.log(procSettingsArray)
                    commit('loadProcSettings', procSettingsArray)
                    commit('setLoading2', false)
                    resolve();
                })
                .catch((response) => {
                    //console.log(procSettingsArray)
                    commit('loadProcSettings', [])
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })

        },
        async loadUserProcSettings ({commit}, IDSettings) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                //commit('setLoading2', true)
                /* try {
                    const procSetting = await firebase.database().ref('userProcSettings').orderByChild("IDSettings").equalTo(IDSettings).once('value')
                    const procSettings = procSetting.val()
                    //console.log(procSettings)
                    const procSettingsArray = []
                    Object.keys(procSettings).forEach(key => {
                        const s = procSettings[key]
                        procSettingsArray.push(
                            new UserProcSettings(
                                s.IDSettings,
                                s.numProcPrice,
                                s.dtDateStart,
                                s.dtDateEnd,
                                key
                            )
                        )
                    })
                    //console.log(procSettingsArray)
                    commit('loadUserProcSettings', procSettingsArray)
                    //commit('setLoading2', false)
                } catch (error) {
                    commit('loadUserProcSettings', [])
                    //commit('setLoading2', false)
                    commit('setError', error.message)
                    //throw error
                } */
                let dataTr = [
                    {"proc":'loadDictionary_ProcSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id, "IDSettings":IDSettings}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    const procSettings = res.data
                    //console.log(procSettings)
                    const procSettingsArray = []
                    Object.keys(procSettings).forEach(key => {
                        const s = procSettings[key]
                        procSettingsArray.push(
                            new UserProcSettings(
                                s.IDSettings,
                                s.numProcPrice,
                                s.dtDateStart,
                                s.dtDateEnd,
                                s.idfr
                            )
                        )
                    })
                    //console.log(procSettingsArray)
                    commit('loadUserProcSettings', procSettingsArray)
                    //commit('setLoading2', false)
                    resolve();
                })
                .catch((response) => {
                    //console.log(procSettingsArray)
                    commit('loadUserProcSettings', [])
                    //commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                });
            })
        },
        async editProcSettings ({commit}, {IDSettings, numProcPrice, dtDateStart, dtDateEnd, id}) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
                /* try {
                
                    await firebase.database().ref('userProcSettings').child(id).update({
                        IDSettings,
                        numProcPrice,
                        dtDateStart,
                        dtDateEnd
                    })
                    // Send mutation
                    commit('editProcSettings', {IDSettings, numProcPrice, dtDateStart, dtDateEnd, id})
            
                    commit('setLoading2', false)
                            
                } catch (error) {
                commit('setLoading2', false)
                commit('setError', error.message)
                throw error
                } */
                let dataTr = [
                    {"proc":'updateDictionary_userProcSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "IDSettings": IDSettings,"numProcPrice": numProcPrice,"dtDateStart": dtDateStart,"dtDateEnd": dtDateEnd,
                    "idfr": id}]
                //console.log(dataTr)
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then(() => {
                    //console.log(res)
                    // Send mutation
                    commit('editProcSettings', {IDSettings, numProcPrice, dtDateStart, dtDateEnd, id})
                    commit('setLoading2', false)
                    resolve();
                })
                .catch((response) => {
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        },
        async deleteProcSettings ({commit}, id) {
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
                /* try {
                await firebase.database().ref('userProcSettings').child(id).remove()
        
                commit('setLoading2', false)
                } catch (error) {
                commit('setLoading2', false)
                commit('setError', error.message)
                throw error
                } */
                let dataTr = [
                    {"proc":'deleteDictionary_userProcSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "idfr": id}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    // Send mutation
                   /*  commit('newSettings', {
                        ...newUserSettings
                    }) */

                    
            
                    commit('setLoading2', false)
                    resolve(res);
                })
                .catch((response) => {
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        },
        async newProcSettings ({commit}, payload) {
            //console.log('13 ' + getters.UserId)
            return new Promise((resolve, reject) => {
                commit('clearError')
                commit('setLoading2', true)
            
                //try {
                // Used helped class
                
                
                //console.log(newUserSettings)
                // Push newTask
                /* var FoundStart = false
                var FoundEnd = false
                var ref = firebase.database().ref("userProcSettings");
                await ref.orderByChild("dtDateStart").equalTo(payload.dtDateStart).once("value")
                .then(async function(snapshot) {
                    //console.log(snapshot.val());
                    if(snapshot.val() !== null){
                        
                        const foundAll = snapshot.val()
                        
                        const foundArray = []
                        Object.keys(foundAll).forEach(key => {
                            const s = foundAll[key]
                            foundArray.push(
                                new UserProcSettings(
                                    s.IDSettings,
                                    s.numProcPrice,
                                    s.dtDateStart,
                                    s.dtDateEnd
                                )
                            )
                        })
                        
                        var FindeType = foundArray.find(o => o.IDSettings == payload.IDSettings);
                        if(FindeType !== undefined)
                        {
                            FoundStart = true
                        }
                    }
                    await ref.orderByChild("dtDateEnd").equalTo(payload.dtDateEnd).once("value")
                    .then(async function(snapshot) {
                        //console.log(snapshot.val());
                        if(snapshot.val() !== null){
                            const foundAll = snapshot.val()
                        
                            const foundArray = []
                            Object.keys(foundAll).forEach(key => {
                                const s = foundAll[key]
                                foundArray.push(
                                    new UserProcSettings(
                                        s.IDSettings,
                                        s.numProcPrice,
                                        s.dtDateStart,
                                        s.dtDateEnd
                                    )
                                )
                            })
                            
                            var FindeType = foundArray.find(o => o.IDSettings == payload.IDSettings);
                            if(FindeType !== undefined)
                            {
                                FoundEnd = true
                            }
                        }

                        if(FoundStart === false && FoundEnd === false)
                        {
                            const setting = await firebase.database().ref('userProcSettings').push(newProcSettings)
                    
                            // Send mutation
                            commit('newProcSettings', {
                            ...newProcSettings,
                            id: setting.key
                            })
                    
                            commit('setLoading2', false)
                        } else {
                            commit('setLoading2', false)
                            commit('setError', 'Настройка с указанными параметрами уже существует!')
                            var error = []
                            error.message = 'Настройка с указанными параметрами уже существует!'
                            throw error
                        }
                    });
                });
                

                
              } catch (error) {
                commit('setLoading2', false)
                commit('setError', error.message)
                throw error
              } */
                const newProcSettings = new UserProcSettings(
                    payload.IDSettings,
                    payload.numProcPrice,
                    payload.dtDateStart,
                    payload.dtDateEnd
                )
                let dataTr = [
                    {"proc":'insertDictionary_userProcSettingsPG', "uid": store.getters.userUID, "uid2": store.getters.user.id,
                    "newProcSettings": newProcSettings}]
                axios({
                    method: 'POST',
                    headers: {
                        'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                        'Content-Type': 'application/json'
                    },
                    url: store.getters.url24sr,
                    //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                    data: dataTr

                })
                .then((res) => {
                    // Send mutation
                /*  commit('newSettings', {
                        ...newUserSettings
                    }) */

                    
            
                    commit('setLoading2', false)
                    resolve(res);
                })
                .catch((response) => {
                    commit('setLoading2', false)
                    commit('setError', response.message)
                    reject(response)
                    //throw error
                })
            })
        }
    },
    getters: {
        procSettings (state) {
            return state.procSettings
        },
        userProcSettings (state) {
            return state.userProcSettings
        }
    }
}