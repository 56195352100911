<template>
    <div id="app">
        <v-app id="inspire">
            <v-content>
                <v-container fluid fill-height>
                    <v-layout align-center justify-center>
                        <v-flex xs12 sm8 md4>
                            <v-card class="elevation-12">
                                <v-toolbar dark color="primary">
                                    <v-toolbar-title>НОВОТЭК. Личный кабинет.</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field prepend-icon="person" name="login" label="E-mail пользователя" type="text" v-model="name"></v-text-field>
                                        <v-text-field prepend-icon="lock" name="password" label="Пароль" id="password" type="password" v-model="password"></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <vue-recaptcha
                                        ref="recaptcha"
                                        size="invisible"
                                        :sitekey="sitekey"
                                        @verify="loginUserCheck"
                                        @expired="onCaptchaExpired"
                                    />
                                    <v-btn color="primary" @click="validate" :disabled="disabled == 1">Вход</v-btn>
                                </v-card-actions>
                            </v-card>
                            <div>
                                <v-alert
                                        v-model="alert"
                                        dismissible
                                        type="error"

                                >
                                    {{ errorMsg }}
                                </v-alert>
                                <v-alert
                                        v-model="info"
                                        dismissible
                                        type="info"

                                >
                                    {{ infoMsg }}
                                </v-alert>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-content>
        </v-app>
    </div>
</template>
<script>
    import Vue from 'vue'
    import axios from 'axios'
    import * as sha512 from 'js-sha512'
    import date from 'date-and-time';

    import Vuetify from 'vuetify'
    import 'vuetify/dist/vuetify.min.css'
    import 'material-design-icons-iconfont/dist/material-design-icons.css'
    import ru from 'vuetify/es5/locale/ru'

    import VueRecaptcha from 'vue-recaptcha'
    

    Vue.use(Vuetify, {
        lang: {
            locales: { 'ru': ru },
            current: 'ru'
        }
    })

    export default {
        name: "loginForm",
        components: { VueRecaptcha },
        data() {
            return {
                name: null,
                password: null,
                errorMsg: "",
                infoMsg: "",
                alert: false,
                info: false,
                dt: "",
                disabled: 0,
                sitekey: '6LfWjuMUAAAAAPN7gTkJQ8in5G3IaMA5v8el-4C-'
            }
        },
        beforeMount(){
            this.$store.commit('Seturl24sr', 'https://api.analyticsnode.ru/apiNode/opti24transportPG')
            this.$store.commit('Seturl24tr', 'https://api.opti-24.ru/')
            this.$store.commit('Seturl24', 'https://api.opti-24.ru/')
        },
        mounted() {
            const now = new Date();
            this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
        },
        methods: {
            validate () {
            // тут можно добавить проверку на валидацию
            // например, с помощью vee validate
            // если с валидацией наших полей все хорошо, запускаем каптчу
                this.$refs.recaptcha.execute()
            },
            onCaptchaExpired () {
                this.$refs.recaptcha.reset()
            },
            async loginUserCheck(recaptchaToken) {
                
                this.disabled = 1
                this.alert = false
                const user = {
                    email: this.name,
                    password: this.password,
                    recaptchaToken: recaptchaToken
                }
                this.info = true
                this.infoMsg = 'Проверка пользователя...'
                //console.log(user)
                //6LfWjuMUAAAAAPN7gTkJQ8in5G3IaMA5v8el-4C-
                //6LfWjuMUAAAAAIF9_1Q7nzBdEyLS3XTKlS2vPatq
                await this.$store.dispatch('loginUser', user)
                    .then(async () => {
                        this.$store.commit('SetUserMail', this.name)

                        Vue.ls.set(this.$store.getters.UserMail, 'РАБОТАЕТ', 15 * 60 * 1000); //expiry 15 minutes

                        this.infoMsg = 'Проверка пользователя...'
                        //console.log(res.status)
                        //this.errorMsg = ok.message
                        //this.alert = true
                        await this.$store.dispatch('loadSettings')
                        .then(async () => {
                            //console.log(33)
                            this.infoMsg = 'Загружаем настройки ...'
                            var settings = this.$store.getters.settings
                            //console.log(settings)
                            //console.log(this.$store.getters.user.id)
                            var UserName = settings.find(o => o.idfr === this.$store.getters.user.id);
                            //var UserName = settings[0];
                            
                            //console.log(UserName)
                            this.$store.commit('SetUserName', UserName.strShortName)
                            this.$store.commit('SetAdmin', UserName.boolIsAdmin)
                            this.$store.commit('SetProc', UserName.numProcPrice)
                            this.$store.commit('SetUserINN', UserName.strINN)
                            //console.log(this.$store.getters.procent)
                            await this.$store.dispatch('loadCardGroupFromSettings')
                            .then(async () => {
                                //console.log('2')
                                await this.$store.dispatch('loadCardStatusDB')
                                .then(async () => {
                                    //console.log(this.$store.getters.cardStatus)
                                    this.infoMsg = 'Загружаем статусы карт ...'
                                    await this.$store.dispatch('loadCountryListDB')
                                    .then(async () => {
                                        this.infoMsg = 'Загружаем справочник стран ...'
                                        await this.$store.dispatch('loadCurrencyListDB')
                                        .then(async () => {
                                            this.infoMsg = 'Загружаем справочник валют ...'
                                            await this.$store.dispatch('loadCurrencyListDB')
                                            .then(async () => {
                                                this.infoMsg = 'Загружаем список групп продукта ...'
                                                await this.$store.dispatch('loadProductGroupListDB')
                                                .then(async () => {
                                                    this.infoMsg = 'Загружаем список измерений ...'
                                                    await this.$store.dispatch('loadUnitListDB')
                                                    .then(async () => {
                                                        this.infoMsg = 'Загружаем список типов продукта ...'
                                                        await this.$store.dispatch('loadProductTypeListDB')
                                                        .then(async () => {
                                                            this.infoMsg = 'Загружаем список регионов ...'
                                                            await this.$store.dispatch('loadRegionListDB')
                                                            .then(async () => {
                                                                this.infoMsg = 'Загружаем список продуктов ...'
                                                                await this.$store.dispatch('loadProductListDB')
                                                                .then(async () => {
                                                                    await this.$store.dispatch('loadOpti24Settings')
                                                                    .then(async () => {
                                                                        var opti24settings = this.$store.getters.opti24settings
                                                                        if(opti24settings.length > 0) {
                                                                            this.$store.commit('Setuser24', opti24settings[0].strUserName)
                                                                            this.$store.commit('Setpass24', opti24settings[0].strUserPassword)
                                                                            this.$store.commit('Setgpn24', opti24settings[0].strKey)
                                                                            this.$store.commit('SetContractId', opti24settings[0].strKontract)
                                                                            
                                                                            var sha = sha512.sha512(this.$store.getters.pass24);
                                                                            const data = "login=" + this.$store.getters.user24 + "&password=" + sha
                                                                            const now = new Date();
                                                                            this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');

                                                                            //console.log("url" + this.$store.getters.url24tr)
                                                                            
                                                                            let dataTr = [
                                                                                {"proc":'authUser', "method":'POST', "apikey":this.$store.getters.gpn24, "date_time":this.dt, "url":this.$store.getters.url24tr + 'vip/v1/authUser', "data":data, "uid": this.$store.getters.userUID, "uid2": this.$store.getters.user.id}]
                                                                            await axios({
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    'api_key': 'OPTI24.1C.15ba560dcd49441c9c7e771abe80aedef903d19e33cb43f88578beff4f4cfd87',
                                                                                    'Content-Type': 'application/json'
                                                                                },
                                                                                url: this.$store.getters.url24sr,
                                                                                //url: 'http://localhost:5000/opti24-335bb/us-central1/LoadOpti241Transport',
                                                                                data: dataTr

                                                                            })
                                                                            .then(async (response) => {
                                                                                this.infoMsg = 'Выполняем вход в Опти24 ...'
                                                                                //handle success
                                                                                //console.log(response);
                                                                                this.$store.commit('SetAlertOpti24False', false)
                                                                                this.$store.commit('SetContractsList', response.data.data.contracts)
                                                                                this.$store.commit('SetSessionId', response.data.data.session_id)
                                                                                this.$store.commit('SetLastContract', this.$store.getters.contractId)

                                                                                
                                                                                /* const setting = {
                                                                                    gpnapi: this.$store.getters.gpn24,
                                                                                    sessionid: this.$store.getters.SessionId,
                                                                                    contractid: this.$store.getters.LastContract,
                                                                                    gpnurl: this.$store.getters.url24,
                                                                                    groupname: this.$store.getters.groupSettings
                                                                                    
                                                                                } */
                                                                                try {
                                                                                await this.$store.dispatch('loadCardGroupDB')
                                                                                .then (async () => {
                                                                                    this.infoMsg = 'Загружаем доступные группы карт ...'
                                                                                    var arr = this.$store.getters.cardGroupsFilter
                                                                                    this.$store.commit('SetCardGroupID', arr[0].id)
                                                                                    const setting1 = {
                                                                                            groupid: arr[0].id,
                                                                                        }
                                                                                    //console.log(setting1)
                                                                                    await this.$store.dispatch('loadCardListDB', setting1)
                                                                                    .then (async () => {
                                                                                        this.infoMsg = 'Загружаем список карт ...'
                                                                                        await this.$store.dispatch('loadCardListAllDB', setting1)
                                                                                        .then (async () => {
                                                                                            await this.$store.dispatch('loadUserProcSettings',this.$store.getters.idSettings)
                                                                                            .then (async () => {
                                                                                                this.$router.push('/mainScreen/analitikaForm')
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                                }
                                                                                catch {
                                                                                    console.log('Cards list Error!')
                                                                                    const setting1 = {
                                                                                        groupid: '-1',
                                                                                    }
                                                                                    //console.log(setting1)
                                                                                    await this.$store.dispatch('loadCardListDB', setting1)
                                                                                    .then(async () => {
                                                                                        await this.$store.dispatch('loadCardListAllDB', setting1)
                                                                                    })
                                                                                    this.$router.push('/mainScreen/analitikaForm')
                                                                                }
                                                                            })
                                                                            .catch((response) => {
                                                                                //handle error
                                                                                console.log(222222)
                                                                                console.log('error', response);
                                                                                console.log('errorType', typeof response);
                                                                                console.log('error', Object.assign({}, response));
                                                                                console.log('getOwnPropertyNames', Object.getOwnPropertyNames(response));
                                                                                console.log('stackProperty', Object.getOwnPropertyDescriptor(response, 'stack'));
                                                                                console.log('messageProperty', Object.getOwnPropertyDescriptor(response, 'message'));
                                                                                //console.log('stackEnumerable', response.propertyIsEnumerable('stack'));
                                                                                //console.log('messageEnumerable', response.propertyIsEnumerable('message'));
                                                                                //console.log(response.response.data.error);
                                                                                this.errorMsg = 'Ошибка подключения к Опти24!'
                                                                                this.alert = true
                                                                                //this.errorMsg = response.response.data.error
                                                                                this.disabled = 0
                                                                                this.$store.commit('SetAlertOpti24False', true)
                                                                                if(this.$store.getters.IsAdmin) {
                                                                                    this.$router.push('/mainScreen/adminForm')
                                                                                } else {
                                                                                    this.$router.push('/mainScreen/analitikaForm')
                                                                                }   
                                                                            });
                                                                        }
                                                                        else {
                                                                            this.$store.commit('SetAlertOpti24False', true)
                                                                            if(this.$store.getters.IsAdmin) {
                                                                                this.$router.push('/mainScreen/adminForm')
                                                                            } else {
                                                                                this.$router.push('/mainScreen/analitikaForm')
                                                                            }
                                                                        }
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                        
                    })
                    .catch(() => {
                        //console.log(error)
                        this.errorMsg = 'Ошибка, проверьте введенные данные!'
                        this.alert = true
                        //this.info = false
                        this.disabled = 0
                        this.$refs.recaptcha.reset()
                    })
                    
            }
        },
        created () {
            const $script = document.createElement('script')
            $script.async = true
            $script.defer = true
            $script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
            document.head.appendChild($script)
        },
        computed: {
            loading () {
                return this.$store.getters.loading
            }
        }
    }
</script>
